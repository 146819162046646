import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BASE_URI, USER_API } from '@liveshare/serverAPI';
import { catchError } from 'rxjs/operators';
import { GUEST_NAME, GUEST_URL } from '@liveshare/Constants';
import { Media } from '@liveshare/entity/Media';
import { EventConfig } from '@liveshare/entity/Event-config';
import { Event } from '@liveshare/entity/Event';
import { environment } from '@lsenvironments/environment';

interface Images {
  popup: string;
  titleBg: string;
  postBgs: Array<string>;
}

export interface ImageFiles {
  selFileList: Array<any>;
  selectedFiles: FileList;
  selectedPopupFiles: FileList;
  removedList: Array<string>;
}

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {

  user: string;

  guestName: string;

  guestUrl: string;

  private tags = new BehaviorSubject([ '' ]);

  private bgs = new BehaviorSubject<Array<Media>>([]);

  private products = new BehaviorSubject<Array<any>>([]);

  private eventConfig = new BehaviorSubject<EventConfig>(null);

  private event = new BehaviorSubject<Array<Event>>([]);

  private images = new BehaviorSubject<Images>(null);

  private imageFiles = new BehaviorSubject<ImageFiles>(null);

  private openBranding = new BehaviorSubject<boolean>(false);

  constructor(private readonly http: HttpClient) {}

  fetchUser(eventId?: string): Observable<any> {

    const url = eventId ? `${environment.serverUrl}${BASE_URI}${USER_API}/me?eventId=${eventId}` : `${environment.serverUrl}${BASE_URI}${USER_API}/me`;
    return this.http.get(url).pipe(catchError((err) => throwError(err)));

  }

  setEvents(event: Array<Event>): void {

    this.event.next(event);

  }

  getEvents(): Observable<Array<Event>> {

    return this.event.asObservable();

  }

  setProducts(products: Array<any>): void {

    this.products.next(products);

  }

  getProducts(): Observable<Array<any>> {

    return this.products.asObservable();

  }

  setEventImagesFiles(imageFiles: ImageFiles): void {

    this.imageFiles.next(imageFiles);

  }

  getEventImagesFiles(): Observable<ImageFiles> {

    return this.imageFiles.asObservable();

  }

  setEventImages(images: Images): void {

    this.images.next(images);

  }

  getEventImages(): Observable<Images> {

    return this.images.asObservable();

  }

  setEventConfig(eventConfig: EventConfig): void {

    this.eventConfig.next(eventConfig);

  }

  getEventConfig(): Observable<EventConfig> {

    return this.eventConfig.asObservable();

  }

  setGuestName(name: string): void {

    this.guestName = name;
    localStorage.setItem(GUEST_NAME, this.guestName);

  }

  getGuestName(): string {

    this.guestName = localStorage.getItem(GUEST_NAME);
    return this.guestName;

  }

  setGuestUrl(url: string): void {

    this.guestUrl = url;
    localStorage.setItem(GUEST_URL, this.guestUrl);

  }

  getGuestUrl(): string {

    this.guestUrl = localStorage.getItem(GUEST_URL);
    return this.guestUrl;

  }

  setTags(tags: Array<string>): void {

    this.tags.next(tags);

  }

  getTags(): Observable<Array<string>> {

    return this.tags.asObservable();

  }

  setBgs(bgs: Array<Media>): void {

    this.bgs.next(bgs);

  }

  getBgs(): Observable<Array<Media>> {

    return this.bgs.asObservable();

  }

  setOpenBranding(openBranding: boolean): void {

    this.openBranding.next(openBranding);

  }

  getOpenBranding(): Observable<boolean> {

    return this.openBranding.asObservable();

  }

}
