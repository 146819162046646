import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginSignupService } from '@lsservices/login-signup.service';

@Component({
  selector: 'app-guest-login',
  templateUrl: './guest-login.component.html',
  styleUrls: [ './guest-login.component.scss' ],
})
export class GuestLoginComponent {

  guestName: string;

  loading = false;

  constructor(
    public dialogRef: MatDialogRef<GuestLoginComponent>,
    private readonly loginSignupService: LoginSignupService
  ) {}

  guestRegisterLogin(): void {

    this.loading = true;
    this.loginSignupService.guestRegisterLogin(this.guestName).subscribe(
      () => {

        this.dialogRef.close(true);
        this.loading = false;

      }, (error) => {

        console.error(error);
        this.loading = false;

      }
    );

  }

}
