<div class="player-container" #player>
  <div class="button-container">
    <a
      (click)="backClicked()"
      class="close close-button text-left float-left button_hover"
      (click)="closeDialog()"
    >
      <!-- <mat-icon>arrow_back</mat-icon> -->
    </a>
    <a
      class="close full-screen-button button_hover"
      (click)="openFullScreen()"
      *ngIf="!fullScreen"
    >
      <mat-icon>fullscreen</mat-icon>
    </a>
    <!-- <a class="close full-screen-button button_hover" (click)="exitFullScreen()" *ngIf="fullScreen">
      <mat-icon>fullscreen_exit</mat-icon>
    </a> -->
  </div>

  <div
    id="container"
    [ngStyle]="
      curPost?.media?.url ? '' : { 'background-color': 'black', margin: '0 0' }
    "
  >
    <!-- '{{curPost?.media?.url}}' (load)="imageLoad()"  -->
    <img #photo src="{{ getUrl(curPost, 'img') }}" alt="Live View" />
    <h1
      [ngStyle]="
        !width.includes('NaNpx')
          ? { width: width }
          : { width: 'calc(100% - 40px)' }
      "
      *ngIf="
        curPost != null &&
        curPost.message?.length > 0 &&
        this.curPost.type == 'Text'
      "
      class="upper_text message-post"
    >
      {{ curPost.message }}
    </h1>
    <div
      *ngIf="curPost?.caption?.length > 0 && this.curPost.media.type == 'Image'"
      class="lower_text"
    >
      <h1 class="text">{{ curCaption }}</h1>
    </div>
  </div>
  <!-- <div class="comments">

    <div class="d-flex align-items-center like-comment">
      <div class="d-flex align-items-center gap">
        <mat-icon style="color: white;">favorite_border</mat-icon>
        <span>{{ curPost?.likesCount }}</span>
      </div>
      <div class="d-flex align-items-center gap">
        <mat-icon style="color: white;">chat_bubble_outline</mat-icon>
        <span>{{ curPost?.commentsCount }}</span>
      </div>
    </div>
  </div> -->
</div>
