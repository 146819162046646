<div
  [class.overlay]="loading"
  [class.spinner]="loading"
  (click)="$event.stopPropagation()"
></div>
<div style="background-color: white; padding: 20px">
  <div class="title">Accounts</div>

  <div class="content">
    <div class="d-flex justify-content-between table-header">
      <div class="d-flex justify-content-center align-items-center search-div">
        <form appearance="none" class="search-input">
          <mat-icon matPrefix class="media-icon">search</mat-icon>
          <input
            matInput
            placeholder="Search"
            (input)="search()"
            [formControl]="searchText"
            class="mediainput"
          />
        </form>
      </div>
    </div>

    <mat-table
      matSort
      (matSortChange)="sortData($event)"
      *ngIf="!loader"
      class="full-width table"
      [dataSource]="accounts"
    >
      <ng-container matColumnDef="Date">
        <mat-header-cell
          mat-sort-header="Date"
          *matHeaderCellDef
          class="table-header-row"
          >Date
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="table-value-row">
          <span class="overflowText">
            {{ showDate(element.createdAt) }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Email">
        <mat-header-cell
          mat-sort-header="Email"
          *matHeaderCellDef
          class="table-header-row"
          >Email
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="table-value-row">
          <span class="name overflowText">
            {{ element.email }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Host">
        <mat-header-cell
          mat-sort-header="Host"
          *matHeaderCellDef
          class="table-header-row"
          >Host
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="table-value-row">
          <span class="overflowText">
            {{ element.name }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Events">
        <mat-header-cell
          mat-sort-header="Events"
          *matHeaderCellDef
          class="table-header-row"
          >Events
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="table-value-row">
          <span class="overflowText">
            {{ element.events }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Type">
        <mat-header-cell *matHeaderCellDef class="table-header-row"
          >Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="table-value-row">
          <span class="overflowText">
            {{ element?.plan || "Basic" }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell
          *matHeaderCellDef
          class="table-header-row actions-column-width"
          >Actions
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="table-value-row actions-column-width"
        >
          <div class="d-flex">
            <mat-icon
              class="d-flex align-items-center icon"
              (click)="removeUser(element._id)"
              style="color: red"
              >delete</mat-icon
            >
            <mat-icon
              class="d-flex align-items-center icon"
              (click)="openBrand(element)"
              >format_bold
            </mat-icon>
            <mat-icon
              class="d-flex align-items-center icon"
              (click)="openEdit(element)"
              >edit
            </mat-icon>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div *ngIf="!loader && accounts.data.length === 0" class="notfound">
      No data available
    </div>
    <div *ngIf="loader">
      <div
        class="loading-container"
        *ngFor="let num of [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]"
        style="margin: 8px 0px 8px 0px"
      >
        <div
          class="loading-box full-width"
          style="height: 45px; border-radius: 2px"
        ></div>
      </div>
    </div>
    <mat-paginator
      [pageSizeOptions]="[10, 20, 30, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
