import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { LoginSignupDialogComponent } from './login-signup/login-signup-dialog/login-signup-dialog.component';
import { LoginSignupComponent } from './login-signup/login-signup.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { environment } from '@lsenvironments/environment';
import { IRHTTPInterceptor } from './helper/IRHTTPInterceptor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';
import { PlayerComponent } from './player/player.component';
import { PlatformModule } from '@angular/cdk/platform';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRippleModule } from '@angular/material/core';
import { VerifyComponent } from './login-signup/verify/verify.component';
import { MatButtonModule } from '@angular/material/button';
import { ForgotResetPasswordComponent } from './login-signup/forgot-reset-password/forgot-reset-password.component';
import { AppAssetComponent } from './app-asset/app-asset.component';
import { reloadGuard } from './helper/reload-guard';
import { GuestLoginComponent } from './login-signup/login-signup-dialog/guest-login/guest-login.component';
import { AccountsComponent } from './tables/accounts/accounts.component';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EditComponent } from './tables/accounts/edit/edit.component';
import { MatSelectModule } from '@angular/material/select';
import { AppInitService } from './app-init.service';

// eslint-disable-next-line func-style
export function initializeApp(appInitService: AppInitService) {

  return (): Promise<any> => appInitService.init();

}

@NgModule({
  declarations: [
    AppComponent,
    LoginSignupComponent,
    LoginSignupDialogComponent,
    PlayerComponent,
    VerifyComponent,
    ForgotResetPasswordComponent,
    AppAssetComponent,
    GuestLoginComponent,
    AccountsComponent,
    EditComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
    FormsModule,
    HttpClientModule,
    SocialLoginModule,
    MatTooltipModule,
    PlatformModule,
    MatBadgeModule,
    MatRippleModule,
    MatButtonModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSortModule,
    MatPaginatorModule,
    MatSelectModule,
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ AppInitService ],
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.fbClientId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IRHTTPInterceptor,
      multi: true,
    },
    DatePipe,
    reloadGuard,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        autoFocus: false,
      },
    },
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule {}
