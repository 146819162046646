import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User, UserS } from '@liveshare/entity/User';
import { BASE_URI, USER_API, LOGIN_API } from '@liveshare/serverAPI';
import { catchError } from 'rxjs/operators';
import { Brand } from '@liveshare/entity/Brand';
import { environment } from '@lsenvironments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginSignupService {

  constructor(private readonly http: HttpClient) {}

  signUp(user: UserS): Observable<unknown> {

    return this.http
      .post<UserS>(`${environment.serverUrl}${BASE_URI}${USER_API}/`, user)
      .pipe(catchError((err) => throwError(err)));

  }

  joinWithLink(linkName: string, passCode: string): Observable<any> {

    return this.http
      .post(`${environment.serverUrl}${BASE_URI}${USER_API}/guest-login/`, { passCode,
        linkName })
      .pipe(catchError((err) => throwError(err)));

  }

  guestRegisterLogin(guestName: string): Observable<{ message: string }> {

    return this.http
      .post<{ message: string }>(
        `${environment.serverUrl}${BASE_URI}${USER_API}/guest-register-login/`, { guestName }
      )
      .pipe(catchError((err) => throwError(err)));

  }

  providerValidation(
    user: UserS
  ): Observable<{ message: string; brand: Brand }> {

    return this.http
      .post<{ message: string; brand: Brand }>(
        `${environment.serverUrl}${BASE_URI}${USER_API}/verifyprovider`, user
      )
      .pipe(catchError((err) => throwError(err)));

  }

  signIn(user: UserS): Observable<{ message: string; brand: Brand }> {

    return this.http
      .post<{ message: string; brand: Brand }>(
        `${environment.serverUrl}${BASE_URI}${USER_API}${LOGIN_API}/`, user
      )
      .pipe(catchError((err) => throwError(err)));

  }

  updateUser(user: User): Observable<any> {

    return this.http
      .post<UserS>(`${environment.serverUrl}${BASE_URI}${USER_API}/update/`, user)
      .pipe(catchError((err) => throwError(err)));

  }

}
