import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginSignupDialogComponent } from 'src/app/login-signup/login-signup-dialog/login-signup-dialog.component';
import { LoginSignupComponent } from 'src/app/login-signup/login-signup.component';

@Component({
  selector: 'app-preview-select',
  templateUrl: './preview-select.component.html',
  styleUrls: [ './preview-select.component.scss' ],
})
export class PreviewSelectComponent {

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public appData: { logo: string }
  ) {}

  preview(type: 'home' | 'login'): void {

    if (type === 'home') {

      this.dialog.open(LoginSignupComponent, {
        data: { preview: true,
          image: this.appData.logo },
        disableClose: true,
        width: '100%',
        maxWidth: '480px',
        height: '100%',
        panelClass: 'plan-dialog',
        autoFocus: false,
      });
      return;

    }
    this.dialog.open(LoginSignupDialogComponent, {
      data: { preview: true,
        image: this.appData.logo },
      disableClose: true,
      width: '100%',
      maxWidth: '480px',
      height: '100%',
      panelClass: 'dialog-bottom-panel-top-fill',
      autoFocus: false,
    });

  }

}
