<div
  [class.overlay]="loading"
  [class.spinner]="loading"
  (click)="$event.stopPropagation()"
></div>

<div class="flex flex-col h-full max-h-full">
  <h1 class="flex items-center">
    <span>Crop Image</span>
    <button class="btn btn-ghost btn-circle ml-auto">
      <mat-icon [mat-dialog-close]="false">close</mat-icon>
    </button>
  </h1>
  <div class="grow overflow-auto" style="max-height: 500px;">
    <div class="input-block align-items-center" style="margin-top: 15px">
      <div *ngIf="this.appData.postImage" style="margin-bottom: 15px">
        <button mat-button [matMenuTriggerFor]="menu">
          <mat-icon>image_aspect_ratio</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="maintainAspectRatio = false">
            Free
          </button>
          <button mat-menu-item (click)="changeAspect(1 / 1)">1 : 1</button>
          <button mat-menu-item (click)="changeAspect(16 / 9)">16 : 9</button>
          <button mat-menu-item (click)="changeAspect(4 / 3)">4 : 3</button>
          <button mat-menu-item (click)="changeAspect(3 / 2)">3 : 2</button>
        </mat-menu>
        <button mat-button (click)="reset()">
          <mat-icon style="transform: scaleX(-1)">refresh</mat-icon>
        </button>
      </div>
      <span
        *ngIf="!imageBase64"
        style="justify-content: center; margin: 5px 0px 5px 0px"
      >
        <div class="retry"></div>
      </span>
      <div>
        <image-cropper
          *ngIf="!appData?.edit"
          [imageBase64]="imageBase64"
          [canvasRotation]="canvasRotation"
          [maintainAspectRatio]="maintainAspectRatio"
          [imageQuality]="100"
          [aspectRatio]="aspectRatio"
          [format]="format"
          (imageCropped)="imageCropped($event)"
          (loadImageFailed)="loadImageFailed()"
        >
        </image-cropper>
        <image-cropper
          *ngIf="appData?.edit"
          [imageURL]="imageBase64"
          [canvasRotation]="canvasRotation"
          [maintainAspectRatio]="maintainAspectRatio"
          [imageQuality]="100"
          [aspectRatio]="aspectRatio"
          [format]="format"
          (imageCropped)="imageCropped($event)"
          (loadImageFailed)="loadImageFailed()"
        >
        </image-cropper>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mt-auto">
    <button class="btn" (click)="close()">Done</button>
  </div>
</div>
