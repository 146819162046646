<div
  [class.overlay]="loading"
  [class.spinner]="loading"
  (click)="$event.stopPropagation()"
></div>

<div style="padding: 15px 25px">
  <div class="d-flex justify-content-between">
    <span class="main-title">Edit Account Plan</span>
    <mat-icon style="cursor: pointer" mat-dialog-close>close</mat-icon>
  </div>
  <div>
    <div class="input-block">
      <span class="title">Account plan<span style="color: red">*</span></span>
      <mat-select
        style="width: 96%; align-items: center; display: grid"
        class="input"
        [(ngModel)]="userPlan.plan"
      >
        <mat-option *ngFor="let code of planCodes" [value]="code"
          >{{ code }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div>
    <div class="center button">
      <button mat-raised-button color="primary" (click)="edit()">Update</button>
    </div>
  </div>
</div>
