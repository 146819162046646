import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventS, Event } from '@liveshare/entity/Event';
import {
  BASE_URI,
  EVENT_API,
  JOINED_EVENT_API,
  EVENT_CONFIG_API,
} from '@liveshare/serverAPI';
import { catchError } from 'rxjs/operators';
import { EventResp } from '@liveshare/common/intf/event.response';
import { EventConfig } from '@liveshare/entity/Event-config';
import { environment } from '@lsenvironments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventService {

  constructor(private readonly http: HttpClient) {}

  getTimeZone(): Observable<any> {

    return this.http.get('../../assets/data/timezone.json');

  }

  createEvent(event: EventS): Observable<EventResp> {

    return this.http
      .post<EventResp>(`${environment.serverUrl}${BASE_URI}${EVENT_API}/`, event)
      .pipe(catchError((err) => throwError(err)));

  }

  updateEvent(event: EventS, eventId: string): Observable<EventResp> {

    return this.http
      .put<EventResp>(`${environment.serverUrl}${BASE_URI}${EVENT_API}/${eventId}`, event)
      .pipe(catchError((err) => throwError(err)));

  }

  addAttendeesViewers(eventId: string): Observable<{ message: string }> {

    return this.http
      .get<{ message: string }>(`${environment.serverUrl}${BASE_URI}${EVENT_API}/count/${eventId}`)
      .pipe(catchError((err) => throwError(err)));

  }

  addDownloadCount(eventId: string): Observable<{ message: string }> {

    return this.http
      .get<{ message: string }>(
        `${environment.serverUrl}${BASE_URI}${EVENT_API}/download-count/${eventId}`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  listAllJoinedEvent(): Observable<Array<Event>> {

    return this.http
      .get<Array<Event>>(`${environment.serverUrl}${BASE_URI}${JOINED_EVENT_API}/`)
      .pipe(catchError((err) => throwError(err)));

  }

  deleteJoinedEvent(eventId: string): Observable<unknown> {

    return this.http['delete'](
      `${environment.serverUrl}${BASE_URI}${JOINED_EVENT_API}/${eventId}`
    ).pipe(catchError((err) => throwError(err)));

  }

  listAllEvent(search?: string): Observable<Array<Event>> {

    const url = search ? `${environment.serverUrl}${BASE_URI}${EVENT_API}?search=${search}` : `${environment.serverUrl}${BASE_URI}${EVENT_API}/`;
    return this.http
      .get<Array<Event>>(url)
      .pipe(catchError((err) => throwError(err)));

  }

  getEvent(eventId: string): Observable<Event> {

    return this.http
      .get<Event>(`${environment.serverUrl}${BASE_URI}${EVENT_API}/${eventId}`)
      .pipe(catchError((err) => throwError(err)));

  }

  generateEventReport(skip: number): Observable<Array<any>> {

    return this.http
      .post<Array<any>>(`${environment.serverUrl}${BASE_URI}${EVENT_API}/event-report`, { skip })
      .pipe(catchError((err) => throwError(err)));

  }

  checkPlanStatus(
    eventId: string
  ): Observable<{ plan: string; selectedPlan: string; guestMessage: string }> {

    return this.http
      .get<{ plan: string; selectedPlan: string; guestMessage: string }>(
        `${environment.serverUrl}${BASE_URI}${EVENT_API}/${eventId}/plan-status`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  getEventByLink(eventId: string): Observable<Event> {

    return this.http
      .get<Event>(`${environment.serverUrl}${BASE_URI}${EVENT_API}/${eventId}`)
      .pipe(catchError((err) => throwError(err)));

  }

  downloadBackupMedia(eventId: string, resolution: string): Observable<any> {

    return this.http
      .get(`${environment.serverUrl}${BASE_URI}${EVENT_API}/backup/${eventId}/${resolution}`)
      .pipe(catchError((err) => throwError(err)));

  }

  downloadLink(eventId: string): Observable<any> {

    return this.http
      .get(`${environment.serverUrl}${BASE_URI}${EVENT_API}/downloadlink/${eventId}`)
      .pipe(catchError((err) => throwError(err)));

  }

  checkFile(link: string): Observable<any> {

    const headers = new HttpHeaders().set('Range', 'bytes=0-1');
    return this.http
      .get(link, { headers })
      .pipe(catchError((err) => throwError(err)));

  }

  deleteEvent(eventId: string): Observable<unknown> {

    return this.http['delete'](`${environment.serverUrl}${BASE_URI}${EVENT_API}/${eventId}`).pipe(
      catchError((err) => throwError(err))
    );

  }

  saveDefaultConfig(event: EventConfig): Observable<EventResp> {

    return this.http
      .post<EventResp>(`${environment.serverUrl}${BASE_URI}${EVENT_CONFIG_API}/`, event)
      .pipe(catchError((err) => throwError(err)));

  }

  saveEventConfig(
    event: EventConfig,
    eventId: string
  ): Observable<{ message: string }> {

    return this.http
      .post<{ message: string }>(
        `${environment.serverUrl}${BASE_URI}${EVENT_CONFIG_API}/${eventId}`, event
      )
      .pipe(catchError((err) => throwError(err)));

  }

  getDefaultConfig(
    plan:
      | 'Basic'
      | 'Personalized'
      | 'Premium'
      | 'PremiumPlus'
      | 'PremiumWithPlayer'
  ): Observable<EventConfig> {

    return this.http
      .get<EventConfig>(`${environment.serverUrl}${BASE_URI}${EVENT_CONFIG_API}/plan/${plan}`)
      .pipe(catchError((err) => throwError(err)));

  }

  getAllDefaultConfig(): Observable<Array<EventConfig>> {

    return this.http
      .get<Array<EventConfig>>(`${environment.serverUrl}${BASE_URI}${EVENT_CONFIG_API}/allDefaultPlan`)
      .pipe(catchError((err) => throwError(err)));

  }

  getEventConfig(eventId: string): Observable<EventConfig> {

    return this.http
      .get<EventConfig>(`${environment.serverUrl}${BASE_URI}${EVENT_CONFIG_API}/${eventId}`)
      .pipe(catchError((err) => throwError(err)));

  }

}
