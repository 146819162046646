import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppAsset } from '@liveshare/entity/AppAsset';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BASE_URI, APP_ASSET_API } from '@liveshare/serverAPI';
import { environment } from '@lsenvironments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppAssetService {

  constructor(private readonly http: HttpClient) {}

  save(app: AppAsset): Observable<{
    _id: string;
    landing: string;
    landingId: string;
    header: string;
    headerId: string;
  }> {

    return this.http
      .post<{
        _id: string;
        landing: string;
        landingId: string;
        header: string;
        headerId: string;
      }>(`${environment.serverUrl}${BASE_URI}${APP_ASSET_API}`, app)
      .pipe(catchError((err) => throwError(err)));

  }

  get(): Observable<AppAsset> {

    return this.http
      .get<AppAsset>(`${environment.serverUrl}${BASE_URI}${APP_ASSET_API}/get`)
      .pipe(catchError((err) => throwError(err)));

  }

}
