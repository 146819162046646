import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ACCESS_TOKEN_ID, HTTP_RESPONSE_CODE } from '@liveshare/Constants';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthRepository } from '@app/states/auth.repository';

@Injectable({
  providedIn: 'root',
})
export class IRHTTPInterceptor implements HttpInterceptor {

  tempLinkName: string;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private authRepo: AuthRepository
  ) {}

  private setCookie = (
    name: string,
    value: string,
    days = 1,
    path = '/'
  ): void => {

    const oneDay = 864e5;
    const expires = new Date(Date.now() + days * oneDay).toUTCString();
    this.document.cookie = `${name} = ${encodeURIComponent(
      value
    )} ; expires= ${expires} ; path= ${path}`;

  };

  private saveToLocalStorage = (key: string, value: string) => {

    if (value) {

      localStorage.setItem(key, value);

    }

  };

  private storeHeaderData = (resp: HttpResponseBase) => {

    const accessToken = resp.headers.get(ACCESS_TOKEN_ID);
    if (accessToken) {

      this.saveToLocalStorage(ACCESS_TOKEN_ID, accessToken);
      // Save to cookie too
      this.setCookie(ACCESS_TOKEN_ID, accessToken);

    }

  };

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    let authReq = req;

    if (localStorage.getItem(ACCESS_TOKEN_ID)) {

      authReq = authReq.clone({
        headers: req.headers.set(
          ACCESS_TOKEN_ID, localStorage.getItem(ACCESS_TOKEN_ID)
        ),
      });

    }

    return next.handle(authReq).pipe(
      catchError((err: HttpErrorResponse) => {

        this.storeHeaderData(err);
        if (err.status === HTTP_RESPONSE_CODE.FORBIDDEN_USER) {

          this.authRepo.logout();
          console.error(HTTP_RESPONSE_CODE.FORBIDDEN_USER);
          const arr = this.router.url.split('/');
          if (
            !this.router.url.includes(`/event/${arr[2]}` || `/event/${arr[2]}/`)
          ) {

            this.router.navigate([ '/' ], { queryParamsHandling: 'preserve' });

          }

        }
        return throwError(err);

      }), map((event: HttpEvent<unknown>) => {

        if (event instanceof HttpResponse) {

          const resp: HttpResponse<unknown> = <HttpResponse<unknown>>event;
          this.storeHeaderData(resp);

        }
        return event;

      })
    );

  }

}
