/* eslint-disable id-length */
import { createStore, select } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  setEntities,
  addEntities,
  getAllEntities,
} from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { EVENT_CONFIG_API } from '@liveshare/serverAPI';
import { EventSConfig } from '@liveshare/entity/Event-config';

export interface DefaultConfig extends EventSConfig {
  _id: number;
}

const store = createStore(
  { name: 'defaultConfig' }, withEntities<DefaultConfig, '_id'>({ idKey: '_id' })
);

@Injectable({ providedIn: 'root' })
export class DefaultConfigRepository {

  defaultConfig$ = store.pipe(selectAllEntities());

  basicConfig$ = store.pipe(selectAllEntities()).pipe(
    select((configs) => configs.find((c) => c.plan === 'Basic' && c.eventId === ''))
  );

  get basicConfig() {

    return store.query(getAllEntities()).find((c) => c.plan === 'Basic');

  }

  constructor(private http: HttpClient) {}

  addDefaultConfig(defaultConfig: DefaultConfig) {

    store.update(addEntities(defaultConfig));

  }

  setDefaultConfig(defaultConfig: DefaultConfig[]) {

    store.update(setEntities(defaultConfig));

  }

  getBasicDefaultConfig() {

    this.http
      .get(`${environment.apiRoot}${EVENT_CONFIG_API}/plan/Basic`)
      .subscribe((result: any) => {

        this.addDefaultConfig(result);

      });

  }

}
