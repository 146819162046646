import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BASE_URI, USER_API } from '@liveshare/serverAPI';
import { catchError } from 'rxjs/operators';
import { User, UserS } from '@liveshare/entity/User';
import { environment } from '@lsenvironments/environment';

interface EventP {
  _id: string;
  name: string;
}

interface Report {
  name: string;
  email: string;
  events: number;
  joinedEvents: number;
  event: EventP;
  joinedEvent: EventP;
  lastAccessed: Date;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private readonly http: HttpClient) {}

  generateReport(skip: number): Observable<Array<Report>> {

    return this.http
      .post<Array<Report>>(`${environment.serverUrl}${BASE_URI}${USER_API}/report`, { skip })
      .pipe(catchError((err) => throwError(err)));

  }

  forgotPassword(emailId: string): Observable<{ message: string }> {

    return this.http
      .post<{ message: string }>(`${environment.serverUrl}${BASE_URI}${USER_API}/forgot-password`, {
        emailId,
      })
      .pipe(catchError((err) => throwError(err)));

  }

  verifyUser(userId: string): Observable<{ message: string }> {

    return this.http
      .post<{ message: string }>(`${environment.serverUrl}${BASE_URI}${USER_API}/verify-user`, {
        userId,
      })
      .pipe(catchError((err) => throwError(err)));

  }

  resendEmail(user: UserS): Observable<{ message: string }> {

    return this.http
      .post<{ message: string }>(`${environment.serverUrl}${BASE_URI}${USER_API}/resend`, user)
      .pipe(catchError((err) => throwError(err)));

  }

  removeUser(): Observable<{ message: string }> {

    return this.http['delete']<{ message: string }>(
      `${environment.serverUrl}${BASE_URI}${USER_API}/remove`
    ).pipe(catchError((err) => throwError(err)));

  }

  removeUserById(userId: string): Observable<{ message: string }> {

    return this.http['delete']<{ message: string }>(
      `${environment.serverUrl}${BASE_URI}${USER_API}/remove/${userId}`
    ).pipe(catchError((err) => throwError(err)));

  }

  listUser(): Observable<Array<User>> {

    return this.http
      .get<Array<User>>(`${environment.serverUrl}${BASE_URI}${USER_API}/list`)
      .pipe(catchError((err) => throwError(err)));

  }

  updateUserPlan(userPlan: {
    _id: string;
    plan:
      | 'Basic'
      | 'Personalized'
      | 'Premium'
      | 'PremiumPlus'
      | 'CorporatePremium'
      | 'CorporatePremiumPlus';
  }): Observable<{ message: string }> {

    return this.http
      .post<{ message: string }>(`${environment.serverUrl}${BASE_URI}${USER_API}/update-plan`, userPlan)
      .pipe(catchError((err) => throwError(err)));

  }

  listAccounts(): Observable<Array<User>> {

    return this.http
      .get<Array<User>>(`${environment.serverUrl}${BASE_URI}${USER_API}/all-accounts`)
      .pipe(catchError((err) => throwError(err)));

  }

  resetPassword(
    oldPassword: string,
    newPassword: string
  ): Observable<{ message: string }> {

    return this.http
      .post<{ message: string }>(`${environment.serverUrl}${BASE_URI}${USER_API}/reset-password`, {
        oldPassword,
        newPassword,
      })
      .pipe(catchError((err) => throwError(err)));

  }

}
