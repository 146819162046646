import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Payment } from '@liveshare/entity/Payment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BASE_URI, PAYMENT_API } from '@liveshare/serverAPI';
import { environment } from '@lsenvironments/environment';

export interface PaymentSession {
  name:
    | 'Basic'
    | 'Personalized'
    | 'Premium'
    | 'PremiumPlus'
    | 'PremiumWithPlayer';
  eventId: string;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentService {

  constructor(private readonly http: HttpClient) {}

  save(payment: Payment): Observable<unknown> {

    return this.http
      .post(`${environment.serverUrl}${BASE_URI}${PAYMENT_API}`, payment)
      .pipe(catchError((err) => throwError(err)));

  }

  checkoutSession(paymentSession: PaymentSession): Observable<{ url: string }> {

    return this.http
      .post<{ url: string }>(
        `${environment.serverUrl}${BASE_URI}${PAYMENT_API}/create-session`, paymentSession
      )
      .pipe(catchError((err) => throwError(err)));

  }

  accountCheckoutSession(
    name:
      | 'Basic'
      | 'Personalized'
      | 'Premium'
      | 'PremiumPlus'
      | 'CorporatePremium'
      | 'CorporatePremiumPlus'
  ): Observable<{ url: string }> {

    return this.http
      .post<{ url: string }>(
        `${environment.serverUrl}${BASE_URI}${PAYMENT_API}/account-create-session`, { name }
      )
      .pipe(catchError((err) => throwError(err)));

  }

  subscriptionSession(brandId: string): Observable<{ url: string }> {

    return this.http
      .post<{ url: string }>(`${environment.serverUrl}${BASE_URI}${PAYMENT_API}/create-subscription`, {
        brandId,
      })
      .pipe(catchError((err) => throwError(err)));

  }

  proSubscriptionSession(brandId: string): Observable<{ url: string }> {

    return this.http
      .post<{ url: string }>(
        `${environment.serverUrl}${BASE_URI}${PAYMENT_API}/create-pro-subscription`, { brandId }
      )
      .pipe(catchError((err) => throwError(err)));

  }

  get(): Observable<Payment> {

    return this.http
      .get<Payment>(`${environment.serverUrl}${BASE_URI}${PAYMENT_API}`)
      .pipe(catchError((err) => throwError(err)));

  }

  paymentStatus(eventId: string): Observable<{ status: string }> {

    return this.http
      .get<{ status: string }>(
        `${environment.serverUrl}${BASE_URI}${PAYMENT_API}/${eventId}/payment-status`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  accountPaymentStatus(): Observable<{ status: string }> {

    return this.http
      .get<{ status: string }>(
        `${environment.serverUrl}${BASE_URI}${PAYMENT_API}/account-payment-status`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  subscriptionStatus(brandId: string): Observable<{ status: string }> {

    return this.http
      .get<{ status: string }>(
        `${environment.serverUrl}${BASE_URI}${PAYMENT_API}/${brandId}/subscription-status`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  proSubscriptionStatus(brandId: string): Observable<{ status: string }> {

    return this.http
      .get<{ status: string }>(
        `${environment.serverUrl}${BASE_URI}${PAYMENT_API}/${brandId}/pro-subscription-status`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  cancelPayment(eventId: string): Observable<{ message: string }> {

    return this.http
      .get<{ message: string }>(
        `${environment.serverUrl}${BASE_URI}${PAYMENT_API}/${eventId}/cancel-payment`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  accountCancelPayment(): Observable<{ message: string }> {

    return this.http
      .get<{ message: string }>(
        `${environment.serverUrl}${BASE_URI}${PAYMENT_API}/account-cancel-payment`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  cancelSubscription(brandId: string): Observable<{ message: string }> {

    return this.http
      .get<{ message: string }>(
        `${environment.serverUrl}${BASE_URI}${PAYMENT_API}/${brandId}/cancel-subscription`
      )
      .pipe(catchError((err) => throwError(err)));

  }

}
