import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class reloadGuard implements CanDeactivate<ComponentCanDeactivate> {

  message =
    'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.';

  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {

    // eslint-disable-next-line no-alert
    return component.canDeactivate() ? true : confirm(this.message);

  }

}
