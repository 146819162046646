<div
  [class.overlay]="loading"
  style="z-index: 9999"
  [class.spinner]="loading"
  (click)="$event.stopPropagation()"
></div>
<div>
  <div>
    <div class="title" style="font-size: 20px; text-align: left">
      Gift free branded events:
    </div>

    <div
      style="
        font-weight: 400;
        font-size: 17px;
        padding: 15px 15px 0px 15px;
        text-align: left;
      "
      class="d-flex align-items-center"
    >
      Use this URL on your website and emails:
    </div>
    <div
      style="font-weight: 400; padding: 5px 15px 20px 15px"
      class="title d-flex align-items-center justify-content-center"
    >
      <div>
        {{ link }}
      </div>
      <mat-icon style="margin: 5px" (click)="copyLink(link, 'Link copied !')"
        >content_copy</mat-icon
      >
    </div>

    <div
      style="
        font-weight: 400;
        font-size: 17px;
        padding: 5px 15px 0px 15px;
        text-align: center;
      "
      class="d-flex align-items-center"
    >
      Use the QR code on business cards, posters, and flyers
    </div>
    <div class="d-flex justify-content-center">
      <img
        style="margin-left: 25px; max-height: 220px; height: 220px"
        [src]="qrCode"
      />
      <mat-icon style="margin: 5px" (click)="downloadImage()"
        >content_copy</mat-icon
      >
    </div>
  </div>

  <div class="d-flex justify-content-center btn-section">
    <div
      style="background: #4751b0; color: white"
      mat-dialog-close
      class="d-flex align-items-center justify-content-center button-common"
    >
      Close
    </div>
  </div>
</div>
