export const BASE_URI = '/api';
export const USER_API = '/users';
export const LOGIN_API = '/login';
export const EVENT_API = '/events';
export const EVENT_CONFIG_API = '/event-config';
export const POST_API = '/posts';
export const MEDIA_API = '/medias';
export const COMMENT_API = '/comments';
export const LIKE_API = '/likes';
export const JOINED_EVENT_API = '/joined-events';
export const COMMENT_LIKE_API = '/commentlikes';
export const PAYMENT_API = '/payment';
export const PLAN_API = '/plan';
export const BRAND_API = '/brand';
export const GIFT_EVENT_API = '/gift-event';
export const GIFT_EVENT_CONFIG_API = '/gift-event-config';
export const PICANOVA_API = '/picanova';
export const PICANOVA_CONFIG_API = '/picanova-config';
export const PICANOVA_CART_API = '/picanova-cart';
export const POPUP_API = '/popup';
export const APP_ASSET_API = '/app-asset';
export const FEEDBACK_API = '/feedbacks';
export const DEVICE_API = '/devices';
