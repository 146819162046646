<div
  [class.overlay]="loading"
  [class.spinner]="loading"
  (click)="$event.stopPropagation()"
></div>

<ng-container *ngIf="appData.mode === 'Forgot'">
  <h1 class="flex items-center w-full">
    Forgot Password
    <button class="btn btn-circle btn-ghost ml-auto" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div mat-dialog-content class="p-1">
    <div class="input-block pb-1" *ngIf="!forgotSuccess">
      <span class="title">Email<span style="color: red">*</span></span>
      <span *ngIf="forgotError" style="color: red">{{ forgotError }}</span>
      <input
        type="text"
        placeholder="Email"
        [(ngModel)]="email"
        (input)="forgotError = ''"
        class="input input-bordered w-full"
      />
    </div>
    <div
      *ngIf="forgotSuccess"
      style="margin-top: 16px; text-align: center; font-size: 17px"
    >
      {{ forgotSuccess }}
    </div>
  </div>

  <div mat-dialog-actions>
    <button
      *ngIf="!forgotSuccess"
      class="btn"
      [disabled]="!email || email?.trim() == ''"
      (click)="forgotPassword()"
    >
      Request
    </button>
    <button *ngIf="forgotSuccess" class="btn btn-primary" mat-dialog-close>
      Ok
    </button>
  </div>
</ng-container>

<ng-container *ngIf="appData.mode === 'Reset'">
  <h1 class="flex items-center w-full">
    Reset Password
    <button class="btn btn-circle btn-ghost ml-auto" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div mat-dialog-content class="p-1">
    <div class="input-block pb-1" *ngIf="resetError">
      <span style="color: red">{{ resetError }}</span>
    </div>
    <div class="input-block pb-1 mb-4">
      <span class="title">Old Password<span style="color: red">*</span></span>
      <input
        type="password"
        placeholder="Old Password"
        [(ngModel)]="oldPassword"
        (input)="resetError = ''"
        class="input input-bordered w-full"
      />
    </div>

    <div class="input-block pb-1 mb-4">
      <span class="title">New Password<span style="color: red">*</span></span>
      <span *ngIf="newPassword !== confNewPassword" style="color: red"
        >Password not matched</span
      >
      <input
        type="password"
        placeholder="New Password"
        [(ngModel)]="newPassword"
        (input)="resetError = ''"
        class="input input-bordered w-full mb-1"
      />
      <input
        type="password"
        placeholder="Confirm New Password"
        [(ngModel)]="confNewPassword"
        (input)="resetError = ''"
        class="input input-bordered w-full"
      />
    </div>
  </div>
  <div>
    <div class="center button">
      <button
        class="btn"
        [disabled]="
          !oldPassword ||
          oldPassword?.trim() == '' ||
          !newPassword ||
          newPassword?.trim() == '' ||
          !confNewPassword ||
          confNewPassword?.trim() == '' ||
          confNewPassword !== newPassword
        "
        (click)="resetPassword()"
      >
        Reset
      </button>
    </div>
  </div>
</ng-container>
