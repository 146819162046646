import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserS } from '@liveshare/entity/User';
import { LoginSignupService } from '@lsservices/login-signup.service';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { CommunicationService } from '@lsservices/communication.service';
import {
  ACCESS_TOKEN_ID,
  GUEST_NAME,
} from '@liveshare/Constants';
import { VerifyComponent } from '../verify/verify.component';
import { BASE_URI, BRAND_API } from '@liveshare/serverAPI';
import { BrandService } from '@lsservices/brand.service';
import { Brand } from '@liveshare/entity/Brand';
import { ForgotResetPasswordComponent } from '../forgot-reset-password/forgot-reset-password.component';
import { GuestLoginComponent } from './guest-login/guest-login.component';
import { AppAssetService } from '@lsservices/app-asset.service';
import { environment } from '@lsenvironments/environment';

@Component({
  selector: 'app-login-signup-dialog',
  templateUrl: './login-signup-dialog.component.html',
  styleUrls: [ './login-signup-dialog.component.scss' ],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(
          '200ms', style({
            opacity: 1,
          })
        ),
      ]),
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '200ms', style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class LoginSignupDialogComponent implements OnInit {

  guestName: string;

  user: UserS = { name: '',
    email: '',
    password: '' };

  linkName = '';

  tempLinkName = false;

  passCode = '';

  password: string;

  confirmPassword: string;

  email: string;

  validEmail: string;

  samePass: string;

  loading = false;

  errorMail = '';

  errorPass = '';

  loginFail: string;

  steps = {
    initial: true,
    guestLogin: false,
    signup: false,
    hostInitial: false,
    hostEventLogin: false,
    guestJoiningLink: false,
  };

  current = 0;

  loggedIn: boolean;

  alreadyIn = false;

  noPassCode = false;

  guestEventId: string;

  mode: string;

  showPassCode = false;

  enableJoinEvent = false;

  logo: string;

  imgDomain: string;

  noEvent = false;

  imgList = [
    'https://picsum.photos/600/400?image=4&grayscale',
    'https://picsum.photos/600/400?image=5&grayscale',
    'https://picsum.photos/600/400?image=6&grayscale',
  ];

  linkChanged: boolean;

  logoLoading = true;

  defaultLogo = '';

  constructor(
    public dialogRef: MatDialogRef<LoginSignupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataJ: any,
    private router: Router,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private brandService: BrandService,
    private appAssetService: AppAssetService,
    private readonly loginSignupService: LoginSignupService,
    private readonly communicationService: CommunicationService,
    private authService: SocialAuthService
  ) {

    this.getDefaultLogo();
    this.guestName = '';
    if (!this.dataJ.isJoin) {

      this.stepSelector('hostInitial');

    } else if (this.dataJ.remember) {
    } else {

      this.stepSelector('guestJoiningLink');
      this.showPassCode = false;

    }
    this.linkName = dataJ.linkName;

  }

  getDefaultLogo(): void {

    this.appAssetService.get().subscribe(
      (app) => {

        if (
          app &&
          app?.logo
        ) {

          this.defaultLogo = `${app?.logo}`;

        } else {

          this.defaultLogo = '/assets/images/ls-logo.png';

        }

      }, (error) => {

        console.error(error);
        this.defaultLogo = '/assets/images/ls-logo.png';

      }
    );

  }

  ngOnInit(): void {

    this.getlogo();
    // JoinWithLink()
    this.tempLinkName = this.router.url.includes(`/event/${this.linkName}` || `/event/${this.linkName}/`);
    if (this.dataJ.remember && !this.router.url.includes(`/event/${this.linkName}` || `/event/${this.linkName}/`)) {

      this.dialogRef.close();

    }
    if (this.linkName) {

      if (localStorage.getItem(ACCESS_TOKEN_ID) && !localStorage.getItem(GUEST_NAME)?.trim()) {

        this.communicationService.setGuestName('');

      } else {

        this.communicationService.setGuestName('Guest');

      }
      this.loginSignupService.joinWithLink(this.linkName, '').subscribe((message) => {

        if (message) {

          this.noPassCode = true;
          this.guestEventId = message.event._id;
          this.stepSelector('guestJoiningLink');

        }

      }, (error) => {

        if (error.error.message === 'No such event') {

          this.noEvent = true;

        } else {

          this.showPassCode = true;
          this.noPassCode = false;
          this.stepSelector('guestJoiningLink');

        }

      });

    }

    this.authService.authState.subscribe((user) => {

      this.loggedIn = user !== null && (user.name !== '' || user.name !== null);
      this.loading = false;
      if (this.loggedIn) {

        this.loginSignupService.providerValidation(user).subscribe((message) => {

          if (message) {

            this.resetFields();
            localStorage.setItem('loginMode', 'provider');
            this.onLogin(this.mode, message?.brand);

          }

        }, (err) => {

          this.resetFields();
          localStorage.removeItem('loginMode');

        });

      }

    });

    this.resetFields();

    const intervalDuration = 500000000000000;
    setInterval(() => {

      this.current = ++this.current % this.imgList.length;

    }, intervalDuration);

  }

  forgotPassword(): void {

    this.dialog.open(ForgotResetPasswordComponent, {
      data: { mode: 'Forgot' },
      disableClose: true,
      width: '100%',
      maxWidth: '400px',
      panelClass: 'plan-dialog',
    });

  }

  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  randName(length: number): string {

    let result = '';
    const charactersLength = this.characters.length;
    for (let initial = 0; initial < length; initial++) {

      result += this.characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );

    }
    return result;

  }

  getlogo(): void {

    this.route.queryParams.subscribe((params) => {

      if (this.dataJ?.preview) {

        this.logo = this.dataJ.image;
        this.logoLoading = false;
        return;

      }
      let brand;
      if (params.brand) {

        brand = `${params.brand}`;

      } else if (localStorage.getItem('brandCode')) {

        brand = localStorage.getItem('brandCode');

      } else {

        this.logoLoading = false;
        return;

      }
      this.brandService.getByCode(brand).subscribe(
        (value) => {

          if (value && value?.code) {

            this.imgDomain = `${environment.serverUrl}${BASE_URI}${BRAND_API}/`;
            this.logo = `${this.imgDomain}${
              value.code
            }/org/logo/${this.randName(3)}`;

          }
          this.logoLoading = false;

        }, (error) => {

          console.error(error);
          this.logoLoading = false;

        }
      );

    });

  }

  getEventDetails(mode: string): void {

    if (this.linkChanged) {

      this.linkChanged = false;
      this.loading = true;
      this.loginFail = '';
      this.communicationService.setGuestName('Guest');
      this.loginSignupService
        .joinWithLink(this.linkName, this.passCode)
        .subscribe(
          (value) => {

            let url = `/event/${value.event.linkName}/event-detail/${value.event._id}`;
            if (this.alreadyIn) {

              url = `/events/event-detail/${value.event._id}`;

            }
            this.router.navigate([ url ], { queryParamsHandling: 'preserve' });
            this.dialogRef.close();
            this.passCode = '';
            this.enableJoinEvent = true;
            this.showPassCode = false;
            // This.loading = false;

          }, (error) => {

            this.passCode = '';
            if (error.error.message === 'Error in creating user session') {

              this.loginFail = '#f44336!important';
              this.errorPass = 'Link name is wrong.';

            } else {

              this.enableJoinEvent = false;
              this.showPassCode = true;

            }
            this.loading = false;

          }
        );

    }

  }

  // eslint-disable-next-line class-methods-use-this
  setLinkChange(): void {

    this.linkChanged = true;

  }

  signInWithGoogle(mode: string): void {

    this.loading = true;
    this.mode = mode;
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      ['catch']((error: unknown) => {

        this.loading = false;
        console.error(error);

      });

  }

  signInWithFB(mode: string): void {

    this.loading = true;
    this.mode = mode;
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      ['catch']((error: unknown) => {

        this.loading = false;
        console.error(error);

      });

  }

  signOut(): void {

    this.authService.signOut();

  }

  nextImage(): void {

    this.current = ++this.current % this.imgList.length;

  }

  prevImage(): void {

    if (this.current <= 0) {

      this.current = this.imgList.length - 1;

    } else {

      this.current = --this.current % this.imgList.length;

    }

  }

  stepSelector(step: string): void {

    if (step === 'guestJoiningLink' && this.noPassCode) {

      const tempUrl = this.dataJ?.single ? `/event/${this.linkName}/event-detail/${this.guestEventId}/view/${this.dataJ?.postId}/single/${this.dataJ?.postId}` : `/event/${this.linkName}/event-detail/${this.guestEventId}/view/${this.dataJ?.postId}`;
      const url = this.dataJ?.postId ? tempUrl : `/event/${this.linkName}/event-detail/${this.guestEventId}`;
      this.router.navigate([ url ], { queryParamsHandling: 'preserve' });
      this.dialogRef.close();

    }
    this.resetFields();
    for (const key in this.steps) {

      if (key === step) {

        this.steps[key] = true;

      } else {

        this.steps[key] = false;

      }
      // Let value = myDictionary[key];

    }

  }

  saveGuestName(): void {

    this.communicationService.setGuestName(this.guestName);

  }

  onLogin(mode: string, brand?: Brand): void {

    if (mode === 'host') {

      this.dialogRef.close(true);
      this.route.queryParams.subscribe((params) => {

        const queryParams = { ...params };
        if (brand && brand?.code) {

          queryParams.brand = brand?.code;

        }
        if (this.dataJ.createEvent) {

          this.router.navigate([ '/events/create-event' ], { queryParams });

        } else if (!this.dataJ.remember) {

          this.router.navigate([ '/events' ], { queryParams });

        }

      });

    } else if (mode === 'join') {

      if (
        !(this.router.url.toString() === '/') &&
        !this.router.url.includes('/post')
      ) {

        window.location.reload();

      }
      if (this.dataJ.remember) {

        this.dialogRef.close(true);
        localStorage.removeItem(GUEST_NAME);

      } else {

        this.alreadyIn = true;
        this.stepSelector('guestJoiningLink');

      }

    }

  }

  register(): void {

    this.loading = true;
    this.loginSignupService.signUp(this.user).subscribe(
      (message) => {

        if (message) {

          this.loading = false;
          this.dialog.open(VerifyComponent, {
            maxWidth: '400px',
            disableClose: true,
          });
          this.steps.signup = false;
          this.steps.hostEventLogin = true;
          this.resetFields();

        }

      }, (err) => {

        if (err) {

          this.loading = false;
          this.validEmail = '#f44336!important';
          this.errorMail = err.error.message;

        }

      }
    );

  }

  joinWithLink(): void {

    this.loading = true;
    this.loginFail = '';
    this.loginSignupService
      .joinWithLink(this.linkName, this.passCode)
      .subscribe(
        (value) => {

          let url = `/event/${value.event.linkName}/event-detail/${value.event._id}`;
          if (this.alreadyIn) {

            url = `/events/event-detail/${value.event._id}`;

          }
          this.router.navigate([ url ], { queryParamsHandling: 'preserve' });
          this.dialogRef.close();
          // This.loading = false;

        }, (error) => {

          this.loading = false;
          this.loginFail = '#f44336!important';
          this.errorPass = 'Link name or passcode is wrong.';

        }
      );

  }

  guestRegisterLoginPopup(): void {

    const guestPopup = this.dialog.open(GuestLoginComponent, {
      width: '100%',
      maxWidth: '400px',
      disableClose: true,
      panelClass: 'set-value',
    });
    guestPopup.afterClosed().subscribe((value) => {

      if (value) {

        this.dialogRef.close(true);
        localStorage.removeItem(GUEST_NAME);

      }

    });

  }

  signIn(mode: string): void {

    this.loading = true;
    this.loginFail = '';
    this.loginSignupService.signIn(this.user).subscribe(
      (message) => {

        if (message.message === 'success') {

          localStorage.setItem('loginMode', '');
          if (mode === 'host') {

            this.dialogRef.close(true);
            this.route.queryParams.subscribe((params) => {

              const queryParams = { ...params };
              if (message?.brand && message?.brand?.code) {

                queryParams.brand = message?.brand?.code;

              }
              if (this.dataJ.createEvent) {

                this.router.navigate([ '/events/create-event' ], { queryParams });

              } else if (!this.dataJ.remember) {

                this.router.navigate([ '/events' ], { queryParams });

              }

            });
            // This.loading = false;

          } else if (mode === 'join') {

            if (!(this.router.url.toString() === '/') && !this.router.url.includes('/post')) {

              window.location.reload();
              this.loading = false;

            }
            if (this.dataJ.remember) {

              this.dialogRef.close(true);
              localStorage.removeItem(GUEST_NAME);

            } else {

              this.alreadyIn = true;
              this.stepSelector('guestJoiningLink');
              this.loading = false;

            }

          }

        }

      }, (err) => {

        this.loading = false;
        this.loginFail = '#f44336!important';
        if (err.error.message.includes('Account not Verified')) {

          this.dialog.open(VerifyComponent, {
            data: {user: this.user},
            maxWidth: '400px',
            disableClose: true,
          });

        }
        this.errorPass = err.error.message;

      }
    );

  }

  resetFields(): void {

    this.user.name = '';
    this.user.email = '';
    this.user.password = '';
    this.email = '';
    this.password = '';
    this.confirmPassword = '';
    this.samePass = '';
    this.validEmail = '';
    this.errorMail = '';
    this.errorPass = '';
    this.loginFail = '';

  }

  validateEmail(): void {

    this.errorMail = '';
    if (this.email) {

      const emailRegEx =
        /^(?<name>[a-zA-Z0-9_\-\.]+)@(?<domain>[a-zA-Z0-9_\-\.]+)\.(?<extn>[a-zA-Z]{2,5})$/gmu;
      this.email = this.email.trim().toLowerCase();
      if (!emailRegEx.test(this.email)) {

        this.errorMail = 'Email id is wrong.';
        this.validEmail = '#f44336!important';
        this.user.email = '';

      } else {

        this.validEmail = '#38EC3A!important';
        this.user.email = this.email;

      }

    } else {

      this.validEmail = '';
      this.user.email = '';

    }

  }

  validatePassword(): void {

    this.errorPass = '';
    if (this.password.length === 0 || this.confirmPassword.length === 0) {

      this.samePass = '';
      this.user.password = '';

    }

    if (this.confirmPassword.length !== 0) {

      if (this.password === this.confirmPassword) {

        this.samePass = '#38EC3A!important';
        this.user.password = this.password;

      } else {

        this.errorPass = 'Password is not same.';
        this.samePass = '#f44336!important';
        this.user.password = '';

      }

    }

  }

}
