import { Component, OnInit } from '@angular/core';
import heic2any from 'heic2any';
import { AppAsset } from '@liveshare/entity/AppAsset';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HTTP_RESPONSE_CODE } from '@liveshare/Constants';
import { PostService } from '@lsservices/post.service';
import { AppAssetService } from '@lsservices/app-asset.service';
import { Router } from '@angular/router';
import { BASE_URI, APP_ASSET_API } from '@liveshare/serverAPI';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '@lsenvironments/environment';

@Component({
  selector: 'app-app-asset',
  templateUrl: './app-asset.component.html',
  styleUrls: [ './app-asset.component.scss' ],
})
export class AppAssetComponent implements OnInit {

  loading = false;

  inputChange = false;

  landingBg = '';

  headerBg = '';

  logo = '';

  selectedbg: FileList;

  selectedHeaderbg: FileList;

  selectedLogo: FileList;

  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  app: AppAsset = {
    landingBg: null,
    headerBg: null,
    logo: null,
  };

  imgDomain = '';

  constructor(
    private postService: PostService,
    private appAssetService: AppAssetService,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AppAssetComponent>
  ) {}

  showSnackbar(message: string): void {

    this.snackBar.open(message, '', {
      duration: 1000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });

  }

  randName(length: number): string {

    let result = '';
    const charactersLength = this.characters.length;
    for (let initial = 0; initial < length; initial++) {

      result += this.characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );

    }
    return result;

  }

  ngOnInit(): void {

    this.imgDomain = `${environment.serverUrl}${BASE_URI}${APP_ASSET_API}/`;
    this.loading = true;
    this.appAssetService.get().subscribe(
      (app) => {

        if (app) {

          this.app = app;

        }
        if (
          app &&
          app?.landingBg
        ) {

          this.landingBg = `${app?.landingBg}`;

        }
        if (
          app &&
          app?.headerBg
        ) {

          this.headerBg = `${app?.headerBg}`;

        }
        if (
          app &&
          app?.logo
        ) {

          this.logo = `${app?.logo}`;

        }
        this.loading = false;

      }, (error) => {

        console.error(error);
        this.loading = false;

      }
    );

  }

  removeLogo(): void {

    this.logo = null;
    this.app.logo = null;
    this.selectedLogo = null;

  }

  removebg(): void {

    this.landingBg = null;
    this.app.landingBg = null;
    this.selectedbg = null;

  }

  removeHeaderbg(): void {

    this.headerBg = null;
    this.app.headerBg = null;
    this.selectedHeaderbg = null;

  }

  mediaCheck(): void {

    if (this.selectedbg) {

      const type =
        this.selectedbg[0].type
          .substring(0, this.selectedbg[0].type.indexOf('/'))
          .toLowerCase() === 'image' ? 'Image' : 'Video';
      const extension = this.selectedbg[0].name.toLowerCase().includes('.heic') ? 'heic' : this.selectedbg[0].type
        .replace(type === 'Image' ? 'image/' : 'video/', '')
        .toLowerCase();
      this.app.landingBg = {
        name: this.selectedbg[0].name
          .toLowerCase()
          .replace('.jpg', '')
          .replace(`.${extension}`, ''),
        type: this.selectedbg[0].name.toLowerCase().includes('.heic') ? 'Image' : type,
        extension,
      };

    }
    if (this.selectedHeaderbg) {

      const type =
        this.selectedHeaderbg[0].type
          .substring(0, this.selectedHeaderbg[0].type.indexOf('/'))
          .toLowerCase() === 'image' ? 'Image' : 'Video';
      const extension = this.selectedHeaderbg[0].name
        .toLowerCase()
        .includes('.heic') ? 'heic' : this.selectedHeaderbg[0].type
          .replace(type === 'Image' ? 'image/' : 'video/', '')
          .toLowerCase();
      this.app.headerBg = {
        name: this.selectedHeaderbg[0].name
          .toLowerCase()
          .replace('.jpg', '')
          .replace(`.${extension}`, ''),
        type: this.selectedHeaderbg[0].name.toLowerCase().includes('.heic') ? 'Image' : type,
        extension,
      };

    }
    if (this.selectedLogo) {

      const type =
        this.selectedLogo[0].type
          .substring(0, this.selectedLogo[0].type.indexOf('/'))
          .toLowerCase() === 'image' ? 'Image' : 'Video';
      const extension = this.selectedLogo[0].name
        .toLowerCase()
        .includes('.heic') ? 'heic' : this.selectedLogo[0].type
          .replace(type === 'Image' ? 'image/' : 'video/', '')
          .toLowerCase();
      this.app.logo = {
        name: this.selectedLogo[0].name
          .toLowerCase()
          .replace('.jpg', '')
          .replace(`.${extension}`, ''),
        type: this.selectedLogo[0].name.toLowerCase().includes('.heic') ? 'Image' : type,
        extension,
      };

    }

  }

  async uploadLogoFile(eResp: {
    _id?: string;
    logo?: string;
    logoId?: string;
  }): Promise<void> {

    const res = await PostService.uploadImage(eResp.logo, this.selectedLogo[0]);
    if (res === HTTP_RESPONSE_CODE.HTTP_OK) {

      this.postService.updateUploaded(eResp.logoId).subscribe(
        () => {

          this.app._id = eResp._id;
          this.app.logo._id = eResp.logoId;
          this.showSnackbar('Logo uploaded');

        }, () => {

          this.showSnackbar('Logo not uploaded!');
          this.loading = false;

        }
      );

    } else {

      this.showSnackbar('Logo not uploaded!');
      this.loading = false;

    }

  }

  async uploadBgFile(eResp: {
    _id?: string;
    landing?: string;
    landingId?: string;
  }): Promise<void> {

    const res = await PostService.uploadImage(
      eResp.landing, this.selectedbg[0]
    );
    if (res === HTTP_RESPONSE_CODE.HTTP_OK) {

      this.postService.updateUploaded(eResp.landingId).subscribe(
        () => {

          this.app._id = eResp._id;
          this.app.landingBg._id = eResp.landingId;
          this.showSnackbar('Landing Background uploaded');

        }, () => {

          this.showSnackbar('Landing Background not uploaded!');
          this.loading = false;

        }
      );

    } else {

      this.showSnackbar('Landing Background not uploaded!');
      this.loading = false;

    }

  }

  async uploadHeaderBgFile(eResp: {
    _id?: string;
    header?: string;
    headerId?: string;
  }): Promise<void> {

    const res = await PostService.uploadImage(
      eResp.header, this.selectedHeaderbg[0]
    );
    if (res === HTTP_RESPONSE_CODE.HTTP_OK) {

      this.postService.updateUploaded(eResp.headerId).subscribe(
        () => {

          this.app._id = eResp._id;
          this.app.headerBg._id = eResp.headerId;
          this.showSnackbar('Header Background uploaded');

        }, () => {

          this.showSnackbar('Header Background not uploaded!');
          this.loading = false;

        }
      );

    } else {

      this.showSnackbar('Header Background not uploaded!');
      this.loading = false;

    }

  }

  async uploadPhotos(resp: {
    _id?: string;
    landing?: string;
    landingId?: string;
  }): Promise<void> {

    if (this.selectedbg) {

      delete this.app.landingBg._id;
      await this.uploadBgFile(resp);

    }
    if (this.selectedHeaderbg) {

      delete this.app.headerBg._id;
      await this.uploadHeaderBgFile(resp);

    }
    if (this.selectedLogo) {

      delete this.app.logo._id;
      await this.uploadLogoFile(resp);

    }

  }

  save(): void {

    this.mediaCheck();
    this.loading = true;
    this.appAssetService.save(this.app).subscribe(
      async (resp) => {

        await this.uploadPhotos(resp);
        this.inputChange = false;
        this.loading = false;

      }, (error) => {

        console.error(error);
        this.loading = false;

      }
    );

  }

  selectFile = (event: Event, type: 'landing' | 'header' | 'logo'): void => {

    this.inputChange = true;
    const fileTag = <HTMLInputElement>event.target;
    if (type === 'landing') {

      this.selectedbg = fileTag.files;

    } else {

      type === 'header' ? this.selectedHeaderbg = fileTag.files : this.selectedLogo = fileTag.files;

    }
    if (fileTag.files[0].name.toLowerCase().includes('.heic')) {

      heic2any({
        blob: fileTag.files[0],
        toType: 'image/jpeg',
      }).then((conversionResult) => {

        const reader = new FileReader();
        reader.readAsDataURL(<Blob>conversionResult);
        reader.onloadend = () => {

          if (type === 'landing') {

            this.landingBg = reader.result.toString();

          } else {

            type === 'header' ? this.headerBg = reader.result.toString() : this.logo = reader.result.toString();

          }

        };

      });
      return;

    }
    const reader = new FileReader();
    reader.readAsDataURL(fileTag.files[0]);
    reader.onload = () => {

      if (type === 'landing') {

        this.landingBg = reader.result.toString();

      } else {

        type === 'header' ? this.headerBg = reader.result.toString() : this.logo = reader.result.toString();

      }

    };

  };

}
