<div
  [class.overlay]="loading"
  style="z-index: 9999"
  [class.spinner]="loading"
  (click)="$event.stopPropagation()"
></div>
<div>
  <div>
    <div class="d-flex justify-content-end" style="margin: 15px 15px 0px 0px">
      <mat-icon class="close-btn-top" [mat-dialog-close]="false"
        >close</mat-icon
      >
    </div>
    <div class="title" style="padding: 0px 20px 10px 20px">App Assets</div>

    <div
      class="d-flex align-items-center justify-content-center"
      style="padding: 0px 20px; flex-direction: column"
    >
      <div style="padding: 5px 20px; width: 100%">App logo</div>
      <div>
        <input
          id="logoImg"
          style="display: none"
          type="file"
          (change)="selectFile($event, 'logo')"
          accept="image/png,image/jpeg,image/jpg,.heic,.heif"
        />
        <label
          class="d-flex align-items-center justify-content-center button-common save-btn"
          style="margin-right: 0px; margin-top: 10px"
          for="logoImg"
        >
          {{ !logo ? "Add Image" : "Change Image" }}
        </label>
      </div>
      <div style="margin-top: 10px" *ngIf="logo">
        <div #bgClose style="position: relative">
          <mat-icon
            style="
              right: 1px;
              position: absolute;
              background: white;
              box-shadow: 0 0 5px 2px #908a8a7a;
              border-radius: 50px;
              top: 1px;
            "
            (click)="removeLogo()"
            >close</mat-icon
          >
        </div>
        <img
          style="height: 80px; object-fit: cover"
          #logoImage
          (load)="
            logoImage.style.display = 'block';
            bgClose.style.display = 'block';
            logoRetry.style.display = 'none'
          "
          (error)="
            logoRetry.style.display = 'flex';
            bgClose.style.display = 'none';
            logoImage.style.display = 'none';
            logoImage.src = logo
          "
          [src]="logo"
        />

        <span
          #logoRetry
          style="
            display: none;
            justify-content: center;
            margin: 5px 0px 5px 0px;
          "
        >
          <div class="retry"></div>
        </span>
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-center"
      style="padding: 0px 20px; flex-direction: column"
    >
      <div style="padding: 5px 20px; width: 100%">
        Default landing page image
      </div>
      <div>
        <input
          id="bgImg"
          style="display: none"
          type="file"
          (change)="selectFile($event, 'landing')"
          accept="image/png,image/jpeg,image/jpg,.heic,.heif"
        />
        <label
          class="d-flex align-items-center justify-content-center button-common save-btn"
          style="margin-right: 0px; margin-top: 10px"
          for="bgImg"
        >
          {{ !landingBg ? "Add Image" : "Change Image" }}
        </label>
      </div>
      <div style="margin-top: 10px" *ngIf="landingBg">
        <div #bgClose style="position: relative">
          <mat-icon
            style="
              right: 1px;
              position: absolute;
              background: white;
              box-shadow: 0 0 5px 2px #908a8a7a;
              border-radius: 50px;
              top: 1px;
            "
            (click)="removebg()"
            >close</mat-icon
          >
        </div>
        <img
          style="height: 80px; object-fit: cover"
          #bgImage
          (load)="
            bgImage.style.display = 'block';
            bgClose.style.display = 'block';
            bgRetry.style.display = 'none'
          "
          (error)="
            bgRetry.style.display = 'flex';
            bgClose.style.display = 'none';
            bgImage.style.display = 'none';
            bgImage.src = landingBg
          "
          [src]="landingBg"
        />

        <span
          #bgRetry
          style="
            display: none;
            justify-content: center;
            margin: 5px 0px 5px 0px;
          "
        >
          <div class="retry"></div>
        </span>
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-center"
      style="padding: 0px 20px; flex-direction: column"
    >
      <div style="padding: 5px 20px; width: 100%">
        Default Header Background image
      </div>
      <div>
        <input
          id="headerbgImg"
          style="display: none"
          type="file"
          (change)="selectFile($event, 'header')"
          accept="image/png,image/jpeg,image/jpg,.heic,.heif"
        />
        <label
          class="d-flex align-items-center justify-content-center button-common save-btn"
          style="margin-right: 0px; margin-top: 10px"
          for="headerbgImg"
        >
          {{ !headerBg ? "Add Image" : "Change Image" }}
        </label>
      </div>
      <div style="margin-top: 10px" *ngIf="headerBg">
        <div #headerbgClose style="position: relative">
          <mat-icon
            style="
              right: 1px;
              position: absolute;
              background: white;
              box-shadow: 0 0 5px 2px #908a8a7a;
              border-radius: 50px;
              top: 1px;
            "
            (click)="removeHeaderbg()"
            >close</mat-icon
          >
        </div>
        <img
          style="height: 80px; object-fit: cover"
          #headerbgImage
          (load)="
            headerbgImage.style.display = 'block';
            headerbgClose.style.display = 'block';
            headerbgRetry.style.display = 'none'
          "
          (error)="
            headerbgRetry.style.display = 'flex';
            headerbgClose.style.display = 'none';
            headerbgImage.style.display = 'none';
            headerbgImage.src = headerBg
          "
          [src]="headerBg"
        />

        <span
          #headerbgRetry
          style="
            display: none;
            justify-content: center;
            margin: 5px 0px 5px 0px;
          "
        >
          <div class="retry"></div>
        </span>
      </div>
    </div>
  </div>

  <div
    class="d-flex btn-section"
    style="justify-content: space-around; margin: 15px 0px"
  >
    <div
      (click)="save()"
      style="margin-right: 0px"
      [ngClass]="
        (inputChange && landingBg) ||
        (inputChange && headerBg) ||
        (inputChange && logo)
          ? 'save-btn'
          : 'save-btn-disable'
      "
      class="d-flex align-items-center justify-content-center button-common"
    >
      Save
    </div>
  </div>
</div>
