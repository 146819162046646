<div>
  <div class="title">Preview</div>
  <div class="btn-div" (click)="preview('home')">
    <mat-icon>home</mat-icon>
    <span>Landing Page</span>
  </div>
  <div class="btn-div" (click)="preview('login')">
    <mat-icon>exit_to_app</mat-icon>
    <span>Login Page</span>
  </div>
  <div
    class="btn-div"
    style="padding: 0px; background: #dfdfdf; margin-top: 25px"
    mat-dialog-close
  >
    <span>Close</span>
  </div>
</div>
