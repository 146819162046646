import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UserService } from '@lsservices/user.service';
import { ConfirmationDialogComponent } from 'src/app/events/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-forgot-reset-password',
  templateUrl: './forgot-reset-password.component.html',
  styleUrls: [ './forgot-reset-password.component.scss' ],
})
export class ForgotResetPasswordComponent {

  loading = false;

  email = '';

  forgotSuccess = '';

  forgotError = '';

  resetError = '';

  oldPassword = '';

  newPassword = '';

  confNewPassword = '';

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ForgotResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public appData: { mode: 'Forgot' | 'Reset' }
  ) {}

  forgotPassword(): void {

    this.loading = true;
    this.userService.forgotPassword(this.email?.trim()).subscribe(
      () => {

        this.loading = false;
        this.forgotSuccess =
          'We have sent you a temporary password. Please check your mail.';

      }, (error) => {

        this.loading = false;
        if (
          [
            'No user found!',
            'Account not Verified.',
            'Account is Deleted.',
            'You have logged in using GOOGLE login.',
            'You have logged in using FACEBOOK login.',
          ].includes(error?.error?.message)
        ) {

          this.forgotError = error.error.message;

        } else {

          this.forgotError = 'Unknown Error! Please try again later';

        }
        console.error(error);

      }
    );

  }

  resetPassword(): void {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title: 'Do you want to reset Password?',
        note: '' },
      width: '100%',
      height: '160px',
      maxWidth: '280px',
    });

    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.loading = true;
        this.userService
          .resetPassword(this.oldPassword, this.newPassword)
          .subscribe(
            () => {

              this.loading = false;
              this.dialogRef.close(true);

            }, (error) => {

              this.loading = false;
              if (
                [
                  'No user found!',
                  'Old Password is incorrect!',
                  'Account not Verified.',
                  'Account is Deleted.',
                  'You have logged in using GOOGLE login.',
                  'You have logged in using FACEBOOK login.',
                ].includes(error?.error?.message)
              ) {

                this.resetError = error.error.message;

              } else {

                this.forgotError = 'Unknown Error! Please try again later';

              }
              console.error(error);

            }
          );

      }

    });

  }

}
