<div
  [class.overlay]="loading"
  [class.spinner]="loading"
  (click)="$event.stopPropagation()"
></div>
<div>
  <div>
    <div class="title">Nickname</div>
    <div style="padding: 0px 20px">
      <div style="margin-top: 10px">
        <input
          type="text"
          placeholder="Nickname"
          [(ngModel)]="guestName"
          class="input"
        />
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end btn-section">
    <div
      (click)="guestRegisterLogin()"
      [ngClass]="guestName?.trim() ? 'save-btn' : 'save-btn-disabled'"
      class="d-flex align-items-center justify-content-center button-common"
    >
      Continue
    </div>
    <div
      [mat-dialog-close]="false"
      class="d-flex align-items-center justify-content-center button-common"
    >
      Cancel
    </div>
  </div>
</div>
