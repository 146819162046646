/* eslint-disable camelcase */
/* eslint-disable no-undefined */
/* eslint-disable id-length */
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { LOGIN_API, MEDIA_API, USER_API } from '@liveshare/serverAPI';
import {
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestStatus,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { ErrorState } from '@ngneat/elf-requests/lib/requests-status';
import { Params, Router } from '@angular/router';
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from 'angularx-social-login';
import { ACCESS_TOKEN_ID, MEDIA_LINK } from '@liveshare/Constants';
import { DOCUMENT } from '@angular/common';
import { User } from '@liveshare/entity/User';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app/hepler/custom-validators';
import { DefaultConfigRepository } from './default-config.repository';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AuthProps {
  loginCallbackRoute?: string;
  loginCallbackRouteParams?: Params | null;
  user?: User;
  accountProfileForm?: FormGroup;
  resetPasswordForm?: FormGroup;
}

const store = createStore(
  { name: 'auth' }, withProps<AuthProps>({}), withRequestsStatus<'auth'>(), withRequestsStatus<'forgotPassword'>(), withRequestsStatus<'resetPassword'>()
);

export const trackRequestsStatus = createRequestsStatusOperator(store);

@Injectable({ providedIn: 'root' })
export class AuthRepository {

  user$ = store.pipe(select((s) => s.user));

  loginErrorMsg$ = store.pipe(
    selectRequestStatus('auth'), select((s) => s as ErrorState['error'] as any)
  );

  forgotPasswordMsg$ = store.pipe(
    selectRequestStatus('forgotPassword'), select((s) => s as ErrorState['error'] as any)
  );

  resetPasswordMsg$ = store.pipe(
    selectRequestStatus('resetPassword'), select((s) => s as ErrorState['error'] as any)
  );

  accountProfileForm$ = store.pipe(select((s) => s.accountProfileForm));

  resetPasswordForm$ = store.pipe(select((s) => s.resetPasswordForm));

  get loginCallbackRoute() {

    return store.getValue().loginCallbackRoute;

  }

  get loginCallbackRouteParams() {

    return store.getValue().loginCallbackRouteParams;

  }

  get user() {

    return store.getValue().user;

  }

  get isLoggedIn() {

    return this.user !== undefined;

  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private defaultConfigRepo: DefaultConfigRepository
  ) {

    /*
     * This.socialAuthService.authState.subscribe(async (user) => {
     *   const loggedIn = user !== null && (user.name !== "" || user.name !== null)
     *   if (loggedIn) {
     *     if (user.provider === "GOOGLE") {
     *       const token = await this.getGoogleAccessToken()
     *       user.authToken = token
     *     }
     *     this.http
     *       .post(`${environment.apiRoot}${USER_API}/verifyprovider`, user)
     *       .subscribe((msg) => {
     *         if (msg) {
     *           this.redirectToUserHome()
     *         }
     *       })
     *   }
     * })
     */
  }

  /*
   * Async getGoogleAccessToken(): Promise<string> {
   *   return await this.socialAuthService.getAccessToken(
   *     GoogleLoginProvider.PROVIDER_ID
   *   )
   * }
   */

  refreshToken() {

    this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);

  }

  get accountProfileForm() {

    return store.getValue().accountProfileForm;

  }

  get resetPasswordForm() {

    return store.getValue().resetPasswordForm;

  }

  login(email: string, password: string) {

    return this.http
      .post(`${environment.apiRoot}/${USER_API}${LOGIN_API}/`, {
        email,
        password,
      })
      .pipe(trackRequestsStatus('auth'));

  }

  verifyUser(user: SocialUser) {

    this.http
      .post(`${environment.apiRoot}${USER_API}/verifyprovider`, user)
      .subscribe((msg) => {

        if (msg) {

          localStorage.setItem('loginMode', 'provider');
          this.redirectToUserHome();

        }

      });

  }

  redirectToUserHome() {

    this.defaultConfigRepo.getBasicDefaultConfig();
    const route = this.loginCallbackRoute || '/events';
    const params = this.loginCallbackRouteParams;
    this.unsetLoginCallbackRoute();
    this.router.navigate([ route ], { queryParams: params });

  }

  resendEmail(email: string) {

    return this.http.post(`${environment.apiRoot}${USER_API}/resend`, {
      email,
    });

  }

  forgotPassword(email: string) {

    return this.http
      .post(`${environment.apiRoot}${USER_API}/resend`, {
        email,
      })
      .pipe(trackRequestsStatus('forgotPassword'));

  }

  checkLoggedIn() {

    return this.http.get(`${environment.apiRoot}${USER_API}/me`).pipe(
      map((result: any) => {

        const user = result as User;
        user.profilePicUrl = !user.profileMedia ? '/assets/images/profile.png' : `${MEDIA_LINK}${user._id}/${user.profileMedia._id}/w720/userpic`;
        store.update(setProp('user', user));
        return user;

      })
    );

  }

  setTempProfilePic(profilePicUrl: string) {

    store.update(
      setProp('user', {
        ...this.user,
        profilePicUrl,
      } as User)
    );

  }

  clearErrorMsg() {

    store.update(updateRequestStatus('auth', 'error', undefined));

  }

  setLoginCallbackRoute(path: string, params?: any) {

    store.update(setProp('loginCallbackRoute', path));
    store.update(setProp('loginCallbackRouteParams', params));

  }

  unsetLoginCallbackRoute() {

    store.update(setProp('loginCallbackRoute', undefined));
    store.update(setProp('loginCallbackRouteParams', undefined));

  }

  setCookie(name: string, value: string, days = 1, path = '/'): void {

    const oneDay = 864e5;
    const expires = new Date(Date.now() + days * oneDay).toUTCString();
    this.document.cookie = `${name} = ${encodeURIComponent(
      value
    )} ; expires= ${expires} ; path= ${path}`;

  }

  logout() {

    try {

      this.socialAuthService.signOut();

    } catch (err) {}
    localStorage.removeItem('GUESTNAME');
    localStorage.removeItem(ACCESS_TOKEN_ID);
    this.setCookie(ACCESS_TOKEN_ID, '');
    this.router.navigate([ '/' ], { queryParamsHandling: 'preserve' });

  }

  updateUser(user: Partial<User>) {

    return this.http.post(`${environment.apiRoot}${USER_API}/update/`, user);

  }

  uploadProfilePic(file: File, url: string) {

    return this.http.put(url, file);

  }

  updateUploadedMedia(mediaId: string) {

    return this.http.put(`${environment.apiRoot}${MEDIA_API}/${mediaId}`, {});

  }

  initAccountForm() {

    const user = this.user as User;
    const { name, address } = user;
    store.update(
      setProp(
        'accountProfileForm', new FormGroup({
          name: new FormControl(name, Validators.required),
          email: new FormControl(address?.email, Validators.email),
          firstname: new FormControl(address?.firstname, Validators.required),
          lastname: new FormControl(address?.lastname, Validators.required),
          street_primary: new FormControl(
            address?.street_primary, Validators.required
          ),
          street_secondary: new FormControl(address?.street_secondary),
          city: new FormControl(address?.city, Validators.required),
          postcode: new FormControl(address?.postcode, Validators.required),
          country_id: new FormControl(address?.country_id),
          region_id: new FormControl(address?.region_id),
          telephone: new FormControl(address?.telephone),
          profilePicUrl: new FormControl(user.profilePicUrl),
          profilePicChanged: new FormControl(false),
          profileMedia: new FormControl(''),
          profilePicFile: new FormControl(''),
        })
      )
    );

  }

  initResetPasswordForm() {

    store.update(
      setProp(
        'resetPasswordForm', new FormGroup(
          {
            oldPassword: new FormControl('', Validators.required),
            newPassword: new FormControl('', Validators.required),
            confirmNewPassword: new FormControl('', Validators.required),
          }, {
            validators: [
              CustomValidators.MatchValidator(
                'newPassword', 'confirmNewPassword'
              ),
            ],
          }
        )
      )
    );

  }

  resetPassword(oldPassword: string, newPassword: string) {

    return this.http
      .post(`${environment.apiRoot}${USER_API}/reset-password`, {
        oldPassword,
        newPassword,
      })
      .pipe(trackRequestsStatus('resetPassword'));

  }

  clearResetPasswordMsg() {

    store.update(updateRequestStatus('resetPassword', 'error', undefined));

  }

  deleteAccount() {

    this.http
      ['delete'](`${environment.apiRoot}${USER_API}/remove`)
      .subscribe(() => {

        this.logout();

      });

  }

}
