<div
  [class.overlay]="loading"
  style="z-index: 9999"
  [class.spinner]="loading"
  (click)="$event.stopPropagation()"
></div>
<div
  [class.overlay]="newTab"
  [class.spinner]="newTab"
  (click)="$event.stopPropagation()"
></div>
<div [style.background]="bgUrl" class="landing">
  <div *ngIf="dataJ?.preview" class="close-div">
    <div
      mat-dialog-close
      (click)="dataJ.preview = false"
      class="close-div-inside"
    >
      <mat-icon class="close-icon">close</mat-icon>
    </div>
  </div>

  <div>
    <div class="d-flex align-items-center justify-content-center">
      <!-- /assets/images/logo/LiveShareNow-Logo-Final-White.png -->
      <img
        *ngIf="!logoLoading"
        class="logo"
        [src]="logo ? logo : defaultLogo"
        alt=""
      />
      <span
        *ngIf="logoLoading"
        style="justify-content: center; margin: 45px 0px 5px 0px"
      >
        <div class="retry"></div>
      </span>
    </div>
    <!-- <div class="border-top"></div> -->
    <div class="qoute">
      CAPTURE PHOTOS. SHARE MEMORIES.
      <!-- PHOTO SHARING MADE EASY! -->
      <!-- CROWDSOURCE YOUR MEMORIES -->
    </div>
    <!-- <div class="border-bottom"></div> -->
  </div>
  <div class="d-flex flex-column btn-container">
    <button class="btn w-80" (click)="openNewWindow('join')">JOIN EVENT</button>
    <button
      class="btn w-80"
      style="margin-bottom: 0px"
      (click)="openNewWindow('manage')"
    >
      MANAGE EVENT
    </button>
    <button class="btn w-80" (click)="openNewWindow('create')">
      CREATE EVENT
    </button>
  </div>
</div>
