/* eslint-disable func-style */
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function randName(length: number): string {

  let result = '';
  const charactersLength = characters.length;
  for (let initial = 0; initial < length; initial++) {

    result += characters.charAt(Math.floor(Math.random() * charactersLength));

  }
  return result;

}
