import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrandService } from '@lsservices/brand.service';
import QRCode from 'qrcode';

@Component({
  selector: 'app-brand-qrcode',
  templateUrl: './brand-qrcode.component.html',
  styleUrls: [ './brand-qrcode.component.scss' ],
})
export class BrandQrcodeComponent {

  qrCode = '';

  loading = false;

  link = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataJ: { link?: string; linkName: string; dontFindBrand?: boolean },
    private brandService: BrandService,
    private readonly snackBar: MatSnackBar
  ) {

    this.link = `${dataJ.link}`;
    if (dataJ.dontFindBrand) {

      QRCode.toDataURL(this.link)
        .then((qrCode) => {

          this.qrCode = qrCode;

        })
        ['catch']((err) => {

          console.error(err);

        });

    } else {

      this.loading = true;
      this.brandService.get().subscribe(
        (brand) => {

          if (brand && brand?.code) {

            this.link = `${this.link}&brand=${brand?.code}`;

          }
          QRCode.toDataURL(this.link)
            .then((qrCode) => {

              this.qrCode = qrCode;

            })
            ['catch']((err) => {

              console.error(err);

            });
          this.loading = false;

        }, (error) => {

          console.error(error);
          this.loading = false;

        }
      );

    }

  }

  copyLink(content: string, message: string): void {

    this.showSnackbar(message);
    navigator.clipboard
      .writeText(content)
      .then()
      ['catch']((error) => console.error(error));

  }

  showSnackbar(message: string): void {

    this.snackBar.open(message, '', {
      duration: 1000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });

  }

  downloadImage(): void {

    const anchor = document.createElement('a');
    anchor.href = this.qrCode;
    anchor.download = `${
      this.dataJ.linkName ? `brand-${this.dataJ.linkName}` : 'brand-qrcode'
    }.png`;
    anchor.click();

  }


}
