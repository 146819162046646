import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BrandService } from '@lsservices/brand.service';
import { PhotoUpload } from '@lsservices/photo-upload.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent {

  status = '';

  constructor(
    public router: Router,
    private brandService: BrandService,
    private photoUpload: PhotoUpload,
    public route: ActivatedRoute
  ) {

    this.photoUpload.status.subscribe((value) => {

      this.status = value;

    });
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {

        gtag('set', 'page', event.urlAfterRedirects);
        gtag('send', 'pageview');

      }

    });

    this.route.queryParams.subscribe((params) => {

      if (params.brand && !localStorage.getItem('brandCode')) {

        this.brandService.getByCode(<string>params.brand).subscribe(
          (value) => {

            if (value) {

              localStorage.setItem('brandCode', params.brand);

            }

          }, (error) => {

            console.error(error);

          }
        );

      }

    });

  }

}
