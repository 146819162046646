import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Declare const Buffer: any;

declare global {
  interface Window {
    rewardful?: any;
  }
}

if (environment.production) {

  enableProdMode();

}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  ['catch']((err) => console.error(err));
