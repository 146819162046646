import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginSignupComponent } from './login-signup/login-signup.component';
import { PlayerComponent } from './player/player.component';
import { AccountsComponent } from './tables/accounts/accounts.component';

const routes: Routes = [

  /*
   * {
   *   path: "",
   *   loadComponent: () =>
   *     import("./pages/home/home.component").then((mod) => mod.HomeComponent),
   * },
   */
  {
    path: '',
    component: LoginSignupComponent,
  },
  {
    path: 'create',
    component: LoginSignupComponent,
  },
  {
    path: 'manage',
    component: LoginSignupComponent,
  },
  {
    path: 'brand',
    component: LoginSignupComponent,
  },
  {
    path: 'join',
    component: LoginSignupComponent,
  },
  {
    path: 'verify/:userId',
    component: LoginSignupComponent,
  },
  {
    path: 'accounts',
    component: AccountsComponent,
  },
  {
    path: 'events',
    loadChildren: () =>
      import('./events/events.module').then((module) => module.EventsModule),
  },
  {
    path: 'event',
    loadChildren: () =>
      import('./events/events.module').then((module) => module.EventsModule),
  },
  { path: 'liveview/:eventLink',
    component: PlayerComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {}
