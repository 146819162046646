/*
 * This file can be replaced during build by using the `fileReplacements` array.
 * `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
 * The list of file replacements can be found in `angular.json`.
 */

export const environment = {
  production: false,
  apiRoot: '/api',
  fbClientId: '178911547316310',
  googleClientId: '184422295827-0ila8tqft5c501tbvkke5mn8v88daskn.apps.googleusercontent.com',
  serverUrl: 'https://api-dev.dsa4me.com'
};

/*
 *FbClientId: '1102431090188347',
 *  googleClientId: '511070809113-4cfqdhk0ammq2mbujccinqfrmbqodgrj.apps.googleusercontent.com'
 */
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// Import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
