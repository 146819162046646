<div class="delete-dialog">
  <div class="d-flex flex-column">
    <div
      *ngIf="appData.title"
      [style.color]="appData?.accountDelete ? 'red' : 'black'"
      class="title"
    >
      <mat-icon class="warning" *ngIf="appData?.accountDelete">warning</mat-icon
      >{{ appData.title }}
    </div>
    <div
      *ngIf="appData.note"
      [style.font-size]="appData?.reset ? '15px' : '12px'"
      [style.color]="appData?.accountDelete ? 'black' : 'grey'"
      class="note"
    >
      {{ appData.note }}
    </div>
  </div>

  <div class="flex">
    <button
      class="btn btn-outline mr-2"
      mat-dialog-close
      (click)="onNoClick(false)"
    >
      Cancel
    </button>
    <button class="btn" mat-dialog-close (click)="onNoClick(true)">
      {{ appData?.reset ? "Reset" : "Yes" }}
    </button>
  </div>
</div>
