/* eslint-disable prefer-destructuring */
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Post } from '@liveshare/entity/Post';
import { PlayerService } from '@lsservices/player.service';
import { interval } from 'rxjs';
import {
  MEDIA_LINK,
  DEFAULT_MEDIA_PATH,
  PLAYER_REFRESH_INTERVAL,
  INTERRUPT_CHECK_INTERVAL,
  PLAN_CODES,
} from '@liveshare/Constants';
import { EVENT_CONFIG_API } from '@liveshare/serverAPI';
import { EventService } from '@lsservices/event.service';
import { environment } from '@lsenvironments/environment';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: [ './player.component.scss' ],
})
export class PlayerComponent implements OnInit, AfterViewInit, OnDestroy {

  fullScreen = false;

  eventId: string;

  eventPlan: string;

  eventLink: string;

  eventPosts: Array<Post> = [];

  currentPage = 0;

  curPost: Post = null;

  playerRefreshTimer: any;

  width: string;

  @ViewChild('photo') photo: ElementRef;

  @ViewChild('player') player: ElementRef;

  newPostCheckTimer: any;

  processedAt: any;

  newPosts: Array<Post> = [];

  interruptStartedAt = 0;

  curCaption = '';

  imgDomain: string;

  passCode: string;

  liveview: boolean;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly activatedRoute: ActivatedRoute,
    private readonly playerService: PlayerService,
    private readonly eventService: EventService
  ) {}

  ngOnInit() {

    this.eventLink = this.activatedRoute.snapshot.paramMap.get('eventLink');

    this.eventService.getEventByLink(this.eventLink);

    this.imgDomain = `${environment.serverUrl}${MEDIA_LINK}`;

    this.getPostsAndPlay();

  }

  getPostsAndPlay(eventPass = ''): void {

    this.passCode = eventPass;
    this.playerService
      .getEventPosts(this.eventLink, '', this.passCode)
      .subscribe((result) => {

        if (result.status === 'success') {

          this.setEventPosts(result.posts, result.slideShowConfig.slots.total);

          this.eventId = result.eventId;
          this.eventPlan = result.plan;
          this.processedAt = result.processedAt;
          this.liveview = result.liveView;

          this.showLiveView(result.slideShowConfig.slots.total);

          this.playerRefreshTimer = interval(PLAYER_REFRESH_INTERVAL).subscribe(
            () => {

              this.showLiveView(result.slideShowConfig.slots.total);

            }
          );

          if (
            this.eventPlan === PLAN_CODES.PREMIUM ||
            this.eventPlan === PLAN_CODES.PREMIUMPLUS ||
            this.eventPlan === PLAN_CODES.PREMIUMWITHPLAYER
          ) {

            this.newPostCheckTimer = interval(
              INTERRUPT_CHECK_INTERVAL
            ).subscribe(() => {

              this.checkNewPosts(result.slideShowConfig.slots.total);

            });

          }

        } else if (result.message === 'Passcode Required') {

          // eslint-disable-next-line no-alert
          const inputS = prompt('Enter Event Passcode:', '');

          this.getPostsAndPlay(inputS);

        } else if (result && result.status !== 'Active') {

          // eslint-disable-next-line no-alert
          alert(result.message);

        }

      });

  }

  setEventPosts(posts: Array<Post>, total: number): void {

    let size = 0;
    posts.forEach((curPost) => {

      if (curPost.status === 'Active' && size < total) {

        this.eventPosts.push(curPost);
        size = this.eventPosts.length;

      }

    });

  }

  ngAfterViewInit(): void {

    this.player?.nativeElement?.addEventListener('fullscreenchange', () => {

      this.fullScreen = !this.fullScreen;

    });

  }

  imageLoad() {

    // eslint-disable-next-line id-length
    const el = this.photo?.nativeElement;
    if (this.curPost.media) {

      if (window.location.origin !== this.curPost.media.url) {

        const ratio = el.naturalWidth / el.naturalHeight;
        let width = el.height * ratio;
        let { height } = el;
        if (width > el.width) {

          width = el.width;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          height = el.width / ratio;

        }
        this.width = `calc(${width}px - 40px)`;
        this.curCaption = this.curPost.caption;

      }

    }

  }

  checkNewPosts(total: number) {

    this.playerService
      .getEventPosts(this.eventLink, this.processedAt, this.passCode)
      .subscribe((result) => {

        this.processedAt = result.processedAt;
        if (result.posts.length > 0) {

          this.newPosts = result.posts;
          this.interruptStartedAt = Date.now();
          this.removeDeletedPosts(total);

        }

      });

  }

  showLiveView(total: number) {

    this.width = '';
    const curTime = Date.now();
    const TIME_INTERVAL_EXTEND = 2000;

    if (
      curTime - this.interruptStartedAt >
      PLAYER_REFRESH_INTERVAL - TIME_INTERVAL_EXTEND
    ) {

      this.interruptStartedAt = 0;

      if (this.newPosts.length > 0) {

        // eslint-disable-next-line prefer-destructuring
        this.curPost = this.newPosts[0];
        this.curCaption = '';
        this.removeDuplicatePost(this.curPost);

        this.eventPosts.splice(0, 0, this.curPost);
        this.eventPosts = this.eventPosts.splice(0, total);
        const tmpArr: any = this.newPosts.shift();
        if (tmpArr?.length > 0) {

          this.newPosts.shift = tmpArr;

        }

      } else if (this.eventPosts.length > 0) {

        this.getNextPhoto();

      }

    }

  }

  getNextPhoto(): void {

    const curIdx = this.currentPage;
    if (this.currentPage === this.eventPosts.length - 1) {

      // If (this.eventPlan === PLAN_CODES.PREMIUM) {
      if (this.liveview) {

        this.currentPage = 0;

      }

    } else {

      this.currentPage++;

    }

    if (this.eventPosts[curIdx]?.status === 'Active') {

      this.curPost = this.eventPosts[curIdx];

    } else {

      this.getNextPhoto();

    }

  }

  getUrl(item: any, mode: string): string {

    const domain = this.imgDomain.replace(
      MEDIA_LINK, `/api${EVENT_CONFIG_API}/`
    );
    let urlS = '';
    if (item && item.media) {

      urlS = item.media.isDefault ? `${domain}${DEFAULT_MEDIA_PATH}/${item.media._id}/org` : `${this.imgDomain}liveview/${this.eventId}/${item.media._id}/org`;
      this.curCaption = this.curPost?.caption;

    } else {

      urlS = '';
      this.curCaption = 'Something went wrong!!!';

    }
    const url = `url('${urlS}')`;
    const src = urlS;

    return mode === 'txt' ? url : src;

  }

  removeDeletedPosts(total: number): void {

    this.newPosts.forEach((value, npIdx) => {

      if (value?.status === 'Deleted') {

        const idx = this.eventPosts.findIndex((post) => post._id === value._id);
        if (idx > 0) {

          this.eventPosts.splice(idx, 1);
          if (this.currentPage > 0) {

            this.currentPage--;

          }

        }
        this.newPosts.splice(npIdx, 1);

      }

    });
    this.showLiveView(total);

  }

  removeDuplicatePost(activePost: Post): void {

    const idx = this.eventPosts.findIndex(
      (post) => post._id === activePost._id
    );
    if (idx > -1) {

      this.eventPosts.splice(idx, 1);

    }

  }

  openFullScreen(): void {

    const elem = this.player?.nativeElement;
    if (elem.requestFullscreen) {

      elem.requestFullscreen();

    } else if (elem.mozRequestFullScreen) {

      elem.mozRequestFullScreen();

    } else if (elem.webkitRequestFullscreen) {

      elem.webkitRequestFullscreen();

    } else if (elem.msRequestFullscreen) {

      elem.msRequestFullscreen();

    }

  }

  exitFullScreen(): void {

    this.fullScreen = false;
    const elem: any = document;
    if (typeof elem.exitFullscreen === 'function') {

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      elem.exitFullscreen;

    } else if (elem.mozCancelFullScreen) {

      elem.mozCancelFullScreen();

    } else if (elem.webkitExitFullscreen) {

      elem.webkitExitFullscreen();

    } else if (elem.msExitFullscreen) {

      elem.msExitFullscreen();

    }

  }

  closeDialog() {

    this.exitFullScreen();

  }

  backClicked() {

    this.location.back();

  }

  ngOnDestroy() {

    this.playerRefreshTimer.unsubscribe();

  }

}
