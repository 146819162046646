import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { PostS, Post } from '@liveshare/entity/Post';
import { HttpClient } from '@angular/common/http';
import {
  BASE_URI,
  POST_API,
  COMMENT_API,
  LIKE_API,
  COMMENT_LIKE_API,
  MEDIA_API,
} from '@liveshare/serverAPI';
import { catchError } from 'rxjs/operators';
import { EventResp } from '@liveshare/common/intf/event.response';
import { Comment } from '@liveshare/entity/Comment';
import { Event } from '@liveshare/entity/Event';
import { environment } from '@lsenvironments/environment';

@Injectable({
  providedIn: 'root',
})
export class PostService {

  constructor(private readonly http: HttpClient) {}

  private image = new BehaviorSubject('');

  sharedImage = this.image.asObservable();

  currentImage(image: any): void {

    this.image.next(image);

  }

  savePost(post: PostS, eventId: string): Observable<EventResp> {

    return this.http
      .post<EventResp>(`${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}`, post)
      .pipe(catchError((err) => throwError(err)));

  }

  editPost(
    post: Partial<PostS>,
    eventId: string,
    postId: string
  ): Observable<{ _id: string }> {

    return this.http
      .put<{ _id: string }>(`${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}/${postId}`, post)
      .pipe(catchError((err) => throwError(err)));

  }

  cropImage(
    post: Post,
    eventId: string,
    postId: string
  ): Observable<{ _id: string; org: string; mediaId: string }> {

    return this.http
      .put<{ _id: string; org: string; mediaId: string }>(
        `${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}/${postId}/crop`, post
      )
      .pipe(catchError((err) => throwError(err)));

  }

  getMostLikedPosts(eventId: string): Observable<Array<Post>> {

    return this.http
      .get<Array<Post>>(`${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}/mostLiked`)
      .pipe(catchError((err) => throwError(err)));

  }

  getAllPosts(eventId: string): Observable<Array<Post>> {

    return this.http
      .get<Array<Post>>(`${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}`)
      .pipe(catchError((err) => throwError(err)));

  }

  getAllPostsIds(
    eventId: string
  ): Observable<{ posts: Array<{ _id: string }>; total: number }> {

    return this.http
      .get<{ posts: Array<{ _id: string }>; total: number }>(
        `${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}/ids`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  deletePost(eventId: string, postId: string): Observable<any> {

    return this.http
      .get(`${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}/${postId}/delete`)
      .pipe(catchError((err) => throwError(err)));

  }

  getPost(eventId: string, postId: string): Observable<Post> {

    return this.http
      .get<Post>(`${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}/${postId}`)
      .pipe(catchError((err) => throwError(err)));

  }

  getFewPost(
    eventId: string,
    limit: number,
    postIds: Array<string>,
    isKeepsake?: boolean
  ): Observable<Array<Post>> {

    return this.http
      .post<Array<Post>>(
        `${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}/posts/limit?&limit=${limit}`, { postIds,
          isKeepsake }
      )
      .pipe(catchError((err) => throwError(err)));

  }

  addComment(post: Post, comment: string): Observable<unknown> {

    return this.http
      .post(`${environment.serverUrl}${BASE_URI}${COMMENT_API}/${post.event}/${post._id}`, { comment })
      .pipe(catchError((err) => throwError(err)));

  }

  addRemovePin(
    postId: string,
    eventId: string
  ): Observable<{ pinned: boolean }> {

    return this.http
      .get<{ pinned: boolean }>(
        `${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}/${postId}/pin`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  keepsakePostCount(eventId: string): Observable<{ count: number }> {

    return this.http
      .get<{ count: number }>(
        `${environment.serverUrl}${BASE_URI}${POST_API}/${eventId}/keepsakePostCount`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  deleteComment(post: Post, event: Event, commentId: string): Observable<any> {

    return this.http
      .get(`${environment.serverUrl}${BASE_URI}${COMMENT_API}/${event._id}/${post._id}/${commentId}`)
      .pipe(catchError((err) => throwError(err)));

  }

  getComments(post: Post): Observable<Array<Comment>> {

    return this.http
      .get<Array<Comment>>(
        `${environment.serverUrl}${BASE_URI}${COMMENT_API}/${post.event}/${post._id}`
      )
      .pipe(catchError((err) => throwError(err)));

  }

  addRemoveLike(post: Post): Observable<unknown> {

    return this.http
      .post(`${environment.serverUrl}${BASE_URI}${LIKE_API}/${post.event}/${post._id}`, {})
      .pipe(catchError((err) => throwError(err)));

  }

  removeGuestlike(post: Post): Observable<unknown> {

    return this.http['delete'](
      `${environment.serverUrl}${BASE_URI}${LIKE_API}/${post.event}/${post._id}/remove`, {}
    ).pipe(catchError((err) => throwError(err)));

  }

  addRemoveCommentLike(post: Post, commentId: string): Observable<unknown> {

    return this.http
      .post(
        `${environment.serverUrl}${BASE_URI}${COMMENT_LIKE_API}/${post.event}/${post._id}/${commentId}`, {}
      )
      .pipe(catchError((err) => throwError(err)));

  }

  updateUploaded(mediaId: string): Observable<unknown> {

    return this.http
      .put(`${environment.serverUrl}${BASE_URI}${MEDIA_API}/${mediaId}`, {})
      .pipe(catchError((err) => throwError(err)));

  }

  static async uploadImage(url: string, file: File): Promise<number> {

    let res: number;
    await fetch(url, { method: 'PUT',
      body: file }).then((response) => {

      res = response.status;

    });
    return res;

  }

}
