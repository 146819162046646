import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '@lsservices/user.service';
import { UserS } from '@liveshare/entity/User';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: [ './verify.component.scss' ],
})
export class VerifyComponent implements OnInit {

  message: string;

  loading = false;

  userData = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public appData: { userId: string; user: UserS },
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {

    if (this.appData && this.appData?.userId) {

      this.loading = true;
      this.userService.verifyUser(this.appData.userId).subscribe(
        (message) => {

          this.message = message.message;
          this.loading = false;

        }, (error) => {

          this.message = 'Invalid Verification Link';
          console.error(error);
          this.loading = false;

        }
      );

    } else if (this.appData && this.appData?.user) {

      this.userData = true;
      this.message =
        'The account is not yet activated, please click on the activation link send to your registered Mail ID.';

    } else {

      this.message =
        'Thank you for creating an account. Please look for an email to confirm your email address (Check Spam folder)';

    }

  }

  routeTo(): void {

    if (this.appData && this.appData?.userId) {

      this.router.navigate([ '/' ], { queryParamsHandling: 'preserve' });

    }

  }

  resendEmail(): void {

    this.loading = true;
    this.userService.resendEmail(this.appData?.user).subscribe(
      () => {

        this.message =
          'Please look for an email to confirm your email address (Check Spam folder)';
        this.userData = false;
        this.loading = false;

      }, (error) => {

        const errorMessage = [
          'Account is Deleted!',
          'Account is already Verified!',
        ];
        if (errorMessage.includes(error?.error?.message)) {

          this.message = error?.error?.message;
          this.userData = false;

        }
        console.error(error);
        this.loading = false;

      }
    );

  }

}
