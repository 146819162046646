<div
  [class.overlay]="loading"
  [class.spinner]="loading"
  (click)="$event.stopPropagation()"
></div>
<div class="d-flex flex-column login">
  <div class="navbar">
    <div class="navbar-start"></div>
    <!-- *ngIf="dataJ.remember" -->
    <span class="navbar-center font-bold">{{
      dataJ?.createEvent ? "CREATE AN EVENT" : "LOGIN OR SIGN UP"
    }}</span>
    <div class="navbar-end">
      <button
        *ngIf="!dataJ.newTab"
        class="btn btn-circle btn-ghost left-0"
        mat-dialog-close
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div
    *ngIf="steps.initial"
    class="d-flex flex-column align-items-center login-container"
  >
    <div *ngIf="!data?.remember" class="d-flex flex-column join-guest">
      <span class="heading">Join as Guest - View-only</span>
      <div class="d-flex flex-column btn-container p-1 w-80">
        <input
          class="input input-bordered w-full mb-2"
          type="text"
          [(ngModel)]="guestName"
          placeholder="Enter Your Name"
          cdk-blur-start
        />
        <button
          [disabled]="!guestName || guestName.length === 0"
          class="btn w-full"
          (click)="saveGuestName(); stepSelector('guestJoiningLink')"
        >
          join as guest
        </button>
      </div>
    </div>
    <div *ngIf="!data?.remember" class="or-divider">
      <div class="or">or</div>
      <div class="divider"></div>
    </div>
    <div class="d-flex flex-column social-login-container">
      <span class="heading">Join as Contributor</span>
      <button
        (click)="signInWithGoogle('join')"
        class="btn btn-outline btn-primary w-80 mb-1"
      >
        <img class="btn-icon mr-auto" src="/assets/images/google.png" alt="" />
        <span class="grow mt-1">Continue With Google</span>
      </button>
      <button
        (click)="signInWithFB('join')"
        class="btn btn-outline btn-primary w-80"
      >
        <img
          class="btn-icon mr-auto"
          src="/assets/images/Facebook.png"
          alt=""
        />
        <span class="grow">Continue With Facebook</span>
      </button>
      <button
        [disabled]="!guestName || guestName.length === 0"
        (click)="stepSelector('guestLogin')"
        class="btn btn-outline btn-primary w-80"
      >
        <img class="btn-icon mr-auto" src="/assets/images/mail.png" alt="" />
        <span class="grow">Continue With Email</span>
      </button>
    </div>
    <div class="text-center mt-10">
      By signing in, I agree to the <br />
      <a href="https://www.livesharenow.com/privacy-policy" target="_blank"
        >Privacy Policy and Terms and conditions</a
      ><br />
      <span style="font-weight: 700">Powered by LiveShare</span>
    </div>
  </div>

  <div
    *ngIf="steps.guestLogin"
    class="d-flex flex-column align-items-center guest-login"
  >
    <div class="d-flex flex-column join-guest">
      <div class="d-flex flex-column btn-container p-1 w-80">
        <div *ngIf="errorPass" class="error-message">
          <spam class="error-message-text">{{ errorPass }}</spam>
        </div>
        <input
          class="input input-bordered w-full mb-2"
          type="email"
          [style.border-color]="loginFail"
          [(ngModel)]="user.email"
          (keyup)="loginFail = ''; errorPass = ''"
          placeholder="Enter Email"
        />
        <input
          class="input input-bordered w-full mb-2"
          type="password"
          [style.border-color]="loginFail"
          [(ngModel)]="user.password"
          (keyup)="loginFail = ''; errorPass = ''"
          placeholder="Enter Password"
        />
        <button
          [disabled]="user.email.length === 0 || user.password.length === 0"
          class="btn w-full"
          (click)="signIn('join')"
        >
          Continue
        </button>
        <div class="new">
          New to Live Share?
          <a (click)="stepSelector('signup')" class="signup"
            >Sign up for free</a
          >
        </div>
        <div class="new">
          Forgot Password?
          <a (click)="forgotPassword()" class="signup">Click Here</a>
        </div>
      </div>
      <div style="margin-top: 30px !important" class="text-center mt-10">
        By signing in, I agree to the <br />
        <a href="https://www.livesharenow.com/privacy-policy" target="_blank"
          >Privacy Policy and Terms and conditions</a
        ><br />
        <span style="font-weight: 700">Powered by LiveShare</span>
      </div>
    </div>
  </div>

  <div
    *ngIf="steps.guestJoiningLink"
    class="d-flex flex-column align-items-center guest-login"
  >
    <div
      *ngIf="noEvent"
      style="height: 60%; text-align: center; margin-left: -25px; width: 83%"
    >
      Event does not exist or has expired. Please check your URL or send email
      to support@lviesharenow.com for assistance.
    </div>
    <div class="d-flex flex-column join-guest" *ngIf="!noEvent">
      <div class="d-flex flex-column btn-container p-1 w-80">
        <div *ngIf="errorPass" class="error-message">
          <spam class="error-message-text">{{ errorPass }}</spam>
        </div>
        <input
          *ngIf="!tempLinkName"
          class="input input-bordered w-full mb-2"
          type="text"
          [style.border-color]="loginFail"
          [(ngModel)]="linkName"
          (keyup)="loginFail = ''; errorPass = ''"
          placeholder="Enter Unique ID"
          (blur)="getEventDetails()"
          (keyup.enter)="getEventDetails()"
          (change)="setLinkChange()"
        />
        <input
          *ngIf="showPassCode"
          class="input input-bordered w-full mb-2"
          type="password"
          [style.border-color]="loginFail"
          [(ngModel)]="passCode"
          (keyup)="loginFail = ''; errorPass = ''"
          placeholder="Enter Passcode"
          (keyup.enter)="joinWithLink()"
        />
        <button
          *ngIf="!(!tempLinkName && showPassCode)"
          [disabled]="
            !(!tempLinkName && linkName.length > 0) &&
            !(showPassCode && passCode.length > 0)
          "
          class="btn w-full"
          (click)="joinWithLink()"
        >
          Join An Event
        </button>
        <button
          *ngIf="!tempLinkName && showPassCode"
          [disabled]="
            (!tempLinkName && linkName.length === 0) ||
            (showPassCode && passCode.length === 0)
          "
          class="btn w-full"
          (click)="joinWithLink()"
        >
          Join An Event
        </button>
        <!-- <div class="new">New to Live Share? <a (click)="stepSelector('signup');linkName = '';" class="signup">Sign up for free</a></div> -->
      </div>
      <!-- <div class="text-center mt-10">By signing in, I agree to the <br> <a href='https://www.livesharenow.com/privacy-policy' target='_blank'>Privacy Policy and Terms and conditions</a></div> -->
    </div>
  </div>

  <div
    style="margin-left: -20px"
    *ngIf="steps.signup"
    class="d-flex flex-column align-items-center signup"
  >
    <div class="d-flex flex-column join-guest">
      <div class="d-flex flex-column btn-container p-1 w-80">
        <div *ngIf="errorMail.length > 0" class="error-message">
          <spam class="error-message-text">{{ errorMail }}</spam>
        </div>
        <div *ngIf="errorPass" class="error-message">
          <spam class="error-message-text">{{ errorPass }}</spam>
        </div>
        <input
          class="input input-bordered w-full mb-2"
          type="text"
          [(ngModel)]="user.name"
          [style.border-color]="user.name.length > 0 ? '#38EC3A' : ''"
          placeholder="Enter Name"
        />
        <input
          class="input input-bordered w-full mb-2"
          type="email"
          [style.border-color]="validEmail"
          [(ngModel)]="email"
          (keyup)="validateEmail()"
          placeholder="Enter Email"
        />
        <input
          class="input input-bordered w-full mb-2"
          [style.border-color]="samePass"
          (keyup)="validatePassword()"
          type="password"
          [(ngModel)]="password"
          placeholder="Enter Password"
        />
        <input
          class="input input-bordered w-full mb-2"
          [style.border-color]="samePass"
          (keyup)="validatePassword()"
          type="password"
          [(ngModel)]="confirmPassword"
          placeholder="Confirm Password"
        />
        <button
          [disabled]="
            user.name.length === 0 ||
            user.email.length === 0 ||
            user.password.length === 0
          "
          class="btn w-full"
          (click)="register()"
        >
          Continue
        </button>
        <div class="new">
          Already signed up?
          <a class="signup" (click)="stepSelector('hostInitial')">Login</a>
        </div>
      </div>
    </div>
    <div class="text-center mt-10">
      By signing in, I agree to the <br />
      <a href="https://www.livesharenow.com/privacy-policy" target="_blank"
        >Privacy Policy and Terms and conditions</a
      ><br />
      <span style="font-weight: 700">Powered by LiveShare</span>
    </div>
  </div>

  <div *ngIf="steps.hostInitial" style="height: 100%">
    <div class="host-event">
      <div
        style="
          height: 185px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
        class="container"
      >
        <div>
          <img
            *ngIf="!logoLoading"
            style="
              width: 180px;
              height: 75px;
              object-fit: contain;
              margin-top: 40px;
            "
            [src]="logo ? logo : defaultLogo"
          />
          <span
            *ngIf="logoLoading"
            style="justify-content: center; margin: 45px 0px 5px 0px"
          >
            <div class="retry"></div>
          </span>
        </div>
        <div
          *ngIf="!dataJ?.postClicked"
          class="text-decor"
          style="margin-bottom: 18px"
        >
          <span
            [style.color]="dataJ?.createEvent ? 'black' : 'white'"
            style="color: black; font-size: 17px; font-weight: 500"
            >Thank you for choosing to create<br />a LiveShare Event!</span
          >
        </div>
        <!-- <div style="margin-top: 25px" class="text-decor">
          Please use<br>Google or Facebook to continue
        </div> -->
        <!-- <div style="margin-top: 20px;
        font-size: 17px;
        color: black;" class="text-decor">
          Login here
        </div> -->
        <div
          *ngIf="dataJ?.postClicked"
          class="text-decor"
          style="line-height: 25px !important"
        >
          <span style="color: black; font-size: 17px; font-weight: 500"
            >Time to share <br />a Memory!</span
          >
        </div>
        <div
          *ngIf="dataJ?.postClicked"
          class="d-flex flex-column social-login-container"
          style="padding-top: 0px"
        >
          <button
            [disabled]="!guestName || guestName.length === 0"
            (click)="
              dataJ?.preview
                ? (dataJ.preview = true)
                : guestRegisterLoginPopup()
            "
            class="btn btn-outline btn-primary w-80"
          >
            <img
              class="btn-icon mr-auto"
              style="height: 30px; width: 30px"
              src="/assets/images/profile.png"
              alt=""
            />
            <div>
              <span
                >Continue as
                <span style="color: #0a538f; font-weight: 700; font-size: 22px"
                  >GUEST</span
                ></span
              ><br />
              <span style="font-size: 10px">Not able to return and edit</span>
            </div>
          </button>
        </div>
        <div class="text-decor">
          <span style="color: black; font-size: 17px; font-weight: 500"
            >Create or Login to your<br /><span
              style="color: #0a538f; font-weight: 700; font-size: 22px"
              >FREE</span
            >
            account</span
          >
        </div>
      </div>
      <div
        style="padding-top: 15px; margin-top: 40px"
        class="d-flex flex-column social-login-container"
      >
        <button
          (click)="
            dataJ?.preview ? (dataJ.preview = true) : signInWithGoogle('host')
          "
          class="btn btn-outline btn-primary w-80 mb-1"
        >
          <img
            class="btn-icon mr-auto mr-auto"
            style="height: 30px; width: 30px"
            src="/assets/images/googleL.png"
            alt=""
          />
          <span class="grow mt-1">Continue With Google</span>
        </button>
        <button
          (click)="
            dataJ?.preview ? (dataJ.preview = true) : signInWithFB('host')
          "
          class="btn btn-outline btn-primary w-80"
        >
          <img
            class="btn-icon mr-auto"
            style="height: 26px; width: 26px"
            src="/assets/images/FacebookLS.png"
            alt=""
          />
          <span class="grow">Continue With Facebook</span>
        </button>
        <!-- <hr style="width: 95%;margin: 15px 0px 10px 0px;">
        <div style="width: 65%;
        display: flex;
        justify-content: space-around;">
          <button style="width: 55px;height: 55px;border: 2px solid rgba(0, 0, 0, 0.2);
          border-radius: 50%;" [ngClass]="guestName.length > 0?'primary':'disable'" (click)="dataJ?.preview ? dataJ.preview = true : signInWithGoogle('host');"
            class="btn btn-outline btn-primary w-80">
            <img class="btn-icon mr-auto" style="height: 30px;
            left: 11px;" src="/assets/images/googleL.png" alt="">
          </button>
          <button style="width: 55px;height: 55px;border: 2px solid rgba(0, 0, 0, 0.2);
          border-radius: 50%;" [ngClass]="guestName.length > 0?'primary':'disable'" (click)="dataJ?.preview ? dataJ.preview = true : signInWithFB('host');"
            class="btn btn-outline btn-primary w-80">
            <img class="btn-icon mr-auto" style="height: 30px;
            left: 11px;" src="/assets/images/FacebookL.png" alt="">
          </button>
        </div> -->
        <hr style="width: 95%; margin: 15px 0px 10px 0px; color: white" />
        <div>
          <div
            style="font-size: 15px; line-height: 25px"
            class="text-decor mb-2"
          >
            Don’t have<br />Facebook or Google?
          </div>
          <button
            (click)="
              dataJ?.preview
                ? (dataJ.preview = true)
                : stepSelector('hostEventLogin')
            "
            class="btn btn-outline btn-primary w-80"
          >
            <img
              class="btn-icon mr-auto"
              src="/assets/images/mail.png"
              alt=""
            />
            <span class="grow">Continue With Email</span>
          </button>
        </div>
      </div>
      <div class="text-center mt-10">
        By signing in, I agree to the <br />
        <a href="https://www.livesharenow.com/privacy-policy" target="_blank"
          >Privacy Policy and Terms and conditions</a
        ><br />
        <span style="font-weight: 700">Powered by LiveShare</span>
      </div>
    </div>
  </div>
  <div *ngIf="steps.hostEventLogin" style="height: 100%">
    <div class="create-event-login">
      <div class="container">
        <div class="sliders">
          <ng-container *ngFor="let img of imgList; let i = index">
            <div class="slider" *ngIf="current == i">
              <button
                class="btn btn-circle btn-ghost mt-10 mr-1"
                (click)="prevImage()"
              >
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div class="image-container" @fade>
                <img class="img" [src]="img" />
              </div>
              <button
                class="btn btn-circle btn-ghost mt-10 ml-1"
                (click)="nextImage()"
              >
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </ng-container>
          <span class="heading">Capture and share photos in real time</span>
          <div class="dot-container">
            <div
              *ngFor="let img of imgList; let i = index"
              [ngStyle]="{ background: current == i ? '#095593' : '#E5E5E5' }"
              class="dot"
            ></div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column join-guest">
        <div class="d-flex flex-column btn-container p-1 w-80">
          <div *ngIf="errorPass" class="error-message">
            <spam class="error-message-text">{{ errorPass }}</spam>
          </div>
          <input
            class="input input-bordered w-full mb-2"
            type="email"
            autocomplete="false"
            [style.border-color]="loginFail"
            (keyup)="loginFail = ''; errorPass = ''"
            [(ngModel)]="user.email"
            placeholder="Enter Email"
          />
          <input
            class="input input-bordered w-full mb-2"
            type="password"
            [(ngModel)]="user.password"
            [style.border-color]="loginFail"
            (keyup)="loginFail = ''; errorPass = ''"
            placeholder="Enter Password"
          />
          <button
            [disabled]="user.email.length === 0 || user.password.length === 0"
            class="btn w-full"
            (click)="signIn('host')"
          >
            Continue
          </button>
          <div class="new">
            New to Live Share?
            <a (click)="stepSelector('signup')" class="signup"
              >Sign up for free</a
            >
          </div>
          <div class="new">
            Forgot Password?
            <a (click)="forgotPassword()" class="signup">Click Here</a>
          </div>
        </div>
      </div>
      <div style="margin-top: 30px !important" class="text-center mt-10">
        By signing in, I agree to the <br />
        <a href="https://www.livesharenow.com/privacy-policy" target="_blank"
          >Privacy Policy and Terms and conditions</a
        ><br />
        <span style="font-weight: 700">Powered by LiveShare</span>
      </div>
    </div>
  </div>
</div>
