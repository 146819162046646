/* eslint-disable id-length */
import { createStore, select, setProp, setProps, withProps } from '@ngneat/elf';
import { withRequestsStatus } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { APP_ASSET_API, BRAND_API, MEDIA_API } from '@liveshare/serverAPI';
import { Brand } from '@liveshare/entity/Brand';
import { map, debounceTime } from 'rxjs/operators';
import { AuthRepository } from './auth.repository';
import { randName } from '@app/hepler/rand-name';
import { ActivatedRoute, Router } from '@angular/router';

export interface WhiteLabelBg {
  extension: string;
  name: string;
  type: string;
  // eslint-disable-next-line no-use-before-define
  uploadStatus: WHITE_LABEL_BG_UPLOAD_STATUS | string;
  uploadedAt: Date;
}

export enum WHITE_LABEL_BG_UPLOAD_STATUS {
  MEDIA_UPLOAD_PROGRESS = 'Uploading',
  MEDIA_UPLOAD_SUCCESS = 'Uploaded',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BrandProps {
  brand?: Brand;
  headerBg?: WhiteLabelBg;
  landingBg?: WhiteLabelBg;
  logo?: WhiteLabelBg;
  brandCode?: string;
}

const store = createStore(
  { name: 'brand' }, withProps<BrandProps>({}), withRequestsStatus<'brand'>()
);

@Injectable({ providedIn: 'root' })
export class BrandRepository {

  brand$ = store.pipe(select((s) => s.brand));

  brandImg$ = store.pipe(select((s) => s.brand?.brandImgUrl));

  landingBg$ = store.pipe(
    select((state) => {

      if (
        state.landingBg &&
        state.landingBg.uploadStatus ===
          WHITE_LABEL_BG_UPLOAD_STATUS.MEDIA_UPLOAD_SUCCESS
      ) {

        return `${environment.apiRoot}/app-asset/org/landing/${randName(4)}`;

      }
      if (!state.landingBg) {

        return '/assets/images/landing-bg_o.png';

      }
      return state.landingBg;

    })
  );

  logo$ = store.pipe(
    select((state) => {

      if (
        state.logo &&
        state.logo.uploadStatus ===
          WHITE_LABEL_BG_UPLOAD_STATUS.MEDIA_UPLOAD_SUCCESS
      ) {

        if (this.brandCode) {

          return `${environment.apiRoot}${BRAND_API}/${
            this.brandCode
          }/org/logo/${randName(3)}`;

        }
        return `${environment.apiRoot}/app-asset/org/logo/${randName(4)}`;

      }
      if (!state.landingBg) {

        return '/assets/images/ls-logo.png';

      }
      // Return state.landingBg;
      return '';

    })
  );

  get logo() {

    return store.getValue().logo;

  }

  get logoImg() {

    const {logo} = store.getValue();
    if (
      logo &&
      logo.uploadStatus === WHITE_LABEL_BG_UPLOAD_STATUS.MEDIA_UPLOAD_SUCCESS
    ) {

      return `${environment.apiRoot}/app-asset/org/logo/${randName(4)}`;

    }
    return '/assets/images/ls-logo.png';

  }

  get brand() {

    return store.getValue().brand;

  }

  get brandCode() {

    return localStorage.getItem('brandCode');

  }

  constructor(
    private http: HttpClient,
    private authRepo: AuthRepository,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  // App init
  load(): Promise<void> {

    return new Promise(async (resolve, reject) => {

      // Brand code checking
      await new Promise((rs, rj) => {

        this.route.queryParams.pipe(debounceTime(500)).subscribe((params) => {

          if (params.brandCode) {

            this.storeBrandCode(params.brandCode);
            this.router.navigate([], {
              queryParams: {
                brandCode: null,
              },
              queryParamsHandling: 'merge',
            });

          } else {

            // eslint-disable-next-line no-undefined
            rs(undefined);

          }

        });

      });

      this.getAppAssset().subscribe(
        (result: any) => {

          if (result) {

            const { headerBg, landingBg, logo } = result;
            store.update(setProps({ headerBg,
              landingBg,
              logo }));

          }
          if (this.brandCode) {

            this.getLogoByCode(this.brandCode).subscribe((logoResult: any) => {

              const { logo } = logoResult;
              if (logo) {

                store.update(setProp('logo', logo));

              }
              resolve();

            });

          } else {

            resolve();

          }

        }, (err) => {

          resolve();

        }
      );

    });

  }

  getLogoByCode(code: string) {

    return this.http.get(`${environment.apiRoot}${BRAND_API}/${code}`);

  }

  getAppAssset() {

    return this.http.get(`${environment.apiRoot}/app-asset/get`);

  }

  storeBrandCode(brandCode: string) {

    localStorage.setItem('brandCode', brandCode);

  }

  clearBrandCode() {

    localStorage.removeItem('brandCode');

  }

  findBrand() {

    this.http
      .post(`${environment.apiRoot}${BRAND_API}/get`, {})
      .subscribe((result) => {

        const brand = this.parseBrandResult(result as Brand);
        store.update(setProp('brand', brand));

      });

  }

  getBrand() {

    return this.http.post(`${environment.apiRoot}${BRAND_API}/get`, {}).pipe(
      map((result) => {

        if (result) {

          const brand = this.parseBrandResult(result as Brand);
          store.update(setProp('brand', brand));
          return brand;

        }
        // eslint-disable-next-line no-undefined
        return undefined;

      })
    );

  }

  parseBrandResult(brand: Brand): Brand {

    const {user} = this.authRepo;
    const brandImgUrl = brand.logo ? `${environment.apiRoot}${BRAND_API}/admin/${user?._id}${randName(
      4
    )}/w720/logo` : `${environment.apiRoot}${APP_ASSET_API}/org/logo/${randName(4)}`;
    return {
      ...brand,
      brandImgUrl,
    };

  }

  updateBrand(logo: any, pro = false) {

    return this.http.put(
      `${environment.apiRoot}${BRAND_API}/${this.brand?._id}`, {
        pro,
        ...this.brand,
        logo,
      }
    );

  }

  resetBrand() {

    return this.http.put(
      `${environment.apiRoot}${BRAND_API}/${this.brand?._id}/reset`, {}
    );

  }

  updateUploadedMedia(mediaId: string) {

    return this.http.put(`${environment.apiRoot}${MEDIA_API}/${mediaId}`, {});

  }

  uploadBrand(file: File, url: string) {

    return this.http.put(url, file);

  }

}
