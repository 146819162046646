/* eslint-disable class-methods-use-this */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import heic2any from 'heic2any';
import { Brand } from '@liveshare/entity/Brand';
import { HTTP_RESPONSE_CODE } from '@liveshare/Constants';
import { BASE_URI, BRAND_API } from '@liveshare/serverAPI';
import { PostService } from '@lsservices/post.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrandService } from '@lsservices/brand.service';
import { Router } from '@angular/router';
import { PaymentService } from '@lsservices/payment.service';
import { interval, Subscription, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { PreviewSelectComponent } from '../preview-select/preview-select.component';
import { User } from '@liveshare/entity/User';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { BrandQrcodeComponent } from '../brand-qrcode/brand-qrcode.component';
import { CropHeaderComponent } from '../../create-event/crop-header/crop-header.component';
import { AppAssetService } from '@lsservices/app-asset.service';
import { environment } from '@lsenvironments/environment';

@Component({
  selector: 'app-add-edit-brand',
  templateUrl: './add-edit-brand.component.html',
  styleUrls: [ './add-edit-brand.component.scss' ],
})
export class AddEditBrandComponent implements OnInit, OnDestroy {

  logo: string;

  header: string;

  defaultLogo = '';

  selectedLogo: FileList;

  selectedbg: FileList;

  brand: Brand = {
    name: '',
    headerImage: null,
    logo: null,
  };

  loading = false;

  imgDomain: string;

  paymentTab: Window;

  statusSubs: Subscription;

  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  inputChange = false;

  logoChange = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public appData: {
      brand: Brand;
      user: User;
      isAdmin: boolean;
      hasDisableOption?: boolean;
    },
    private postService: PostService,
    private snackBar: MatSnackBar,
    private paymentService: PaymentService,
    private appAssetService: AppAssetService,
    private brandService: BrandService,
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditBrandComponent>
  ) {

    if (this.appData.isAdmin) {

      this.brand.createdBy = this.appData.user;

    }

  }

  showDisableBtn(): boolean {

    if (
      this.appData?.brand &&
      this.appData?.brand?.subscriptionStartDate &&
      this.appData?.brand?.subscriptionEndDate &&
      new Date() < new Date(this.appData.brand.subscriptionEndDate)
    ) {

      return true;

    }
    return false;

  }

  showSnackbar(message: string): void {

    this.snackBar.open(message, '', {
      duration: 1000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });

  }

  getDefaultlogo(): void {

    this.appAssetService.get().subscribe((app) => {

      this.defaultLogo = `${app?.logo}`;

    });

  }

  randName(length: number): string {

    let result = '';
    const charactersLength = this.characters.length;
    for (let initial = 0; initial < length; initial++) {

      result += this.characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );

    }
    return result;

  }

  ngOnInit(): void {

    if (this.appData.brand) {

      this.brand = { ...this.appData.brand };
      this.imgDomain = `${environment.serverUrl}${BASE_URI}${BRAND_API}/`;
      if (this.appData.isAdmin) {

        this.logo = this.appData.brand.logo ? `${this.imgDomain}admin/${this.appData.user._id}${this.randName(
          4
        )}/w720/logo` : null;
        this.header = this.appData.brand.headerImage ? `${this.imgDomain}admin/${this.appData.user._id}${this.randName(
          4
        )}/w720/bg` : null;

      } else {

        this.logo = this.appData.brand.logo ? `${this.imgDomain}admin/${this.appData.brand.logo._id}/w720/logo` : null;
        this.header = this.appData.brand.headerImage ? `${this.imgDomain}admin/${this.appData.brand.logo._id}/w720/bg` : null;

      }

    }
    this.getDefaultlogo();

  }

  disable(): void {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title: 'Do you want to disable branding?',
        note: '' },
      width: '100%',
      height: '160px',
      maxWidth: '280px',
    });

    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.brandService.disable(this.appData.user).subscribe(
          () => {

            this.loading = false;
            this.dialogRef.close(true);

          }, (error) => {

            console.error(error);
            this.loading = false;

          }
        );

      }

    });

  }

  removeLogo(): void {

    this.logo = null;
    this.brand.logo = null;
    this.selectedLogo = null;

  }

  removebg(): void {

    this.header = null;
    this.brand.headerImage = null;
    this.selectedbg = null;

  }

  openQrcodePopup(brandId: string): void {

    const domain = location.href.replace(this.router.url, '');
    this.dialog.open(BrandQrcodeComponent, {
      data: {
        link: `${domain}/create?brand=${brandId}`,
        linkName: brandId,
        dontFindBrand: true,
      },
      width: '100%',
      maxWidth: '400px',
      disableClose: true,
      panelClass: 'set-value',
    });

  }

  checkSubscription(): boolean {

    if (
      this.appData?.brand &&
      this.appData?.brand?.subscriptionEndDate &&
      new Date(this.appData?.brand?.subscriptionEndDate) < new Date()
    ) {

      return true;

    }
    return false;

  }

  mediaCheck(): void {

    if (this.selectedLogo) {

      const type =
        this.selectedLogo[0].type
          .substring(0, this.selectedLogo[0].type.indexOf('/'))
          .toLowerCase() === 'image' ? 'Image' : 'Video';
      const extension = this.selectedLogo[0].name
        .toLowerCase()
        .includes('.heic') ? 'heic' : this.selectedLogo[0].type
          .replace(type === 'Image' ? 'image/' : 'video/', '')
          .toLowerCase();
      this.brand.logo = {
        name: this.selectedLogo[0].name
          .toLowerCase()
          .replace('.jpg', '')
          .replace(`.${extension}`, ''),
        type: this.selectedLogo[0].name.toLowerCase().includes('.heic') ? 'Image' : type,
        extension,
      };

    }

    if (this.selectedbg) {

      const type =
        this.selectedbg[0].type
          .substring(0, this.selectedbg[0].type.indexOf('/'))
          .toLowerCase() === 'image' ? 'Image' : 'Video';
      const extension = this.selectedbg[0].name.toLowerCase().includes('.heic') ? 'heic' : this.selectedbg[0].type
        .replace(type === 'Image' ? 'image/' : 'video/', '')
        .toLowerCase();
      this.brand.headerImage = {
        name: this.selectedbg[0].name
          .toLowerCase()
          .replace('.jpg', '')
          .replace(`.${extension}`, ''),
        type: this.selectedbg[0].name.toLowerCase().includes('.heic') ? 'Image' : type,
        extension,
      };

    }

  }

  async uploadPopupFile(eResp: {
    _id?: string;
    org?: string;
    mediaId?: string;
  }): Promise<void> {

    // This.showSnackbar('Uploading Logo');
    const res = await PostService.uploadImage(eResp.org, this.selectedLogo[0]);
    if (res === HTTP_RESPONSE_CODE.HTTP_OK) {

      this.postService.updateUploaded(eResp.mediaId).subscribe(
        () => {

          this.brand._id = eResp._id;
          this.brand.logo._id = eResp.mediaId;
          this.showSnackbar('Logo uploaded');

        }, () => {

          this.showSnackbar('Logo not uploaded!');
          this.loading = false;

        }
      );

    } else {

      this.showSnackbar('Logo not uploaded!');
      this.loading = false;

    }

  }

  async uploadBgFile(eResp: {
    _id?: string;
    orgBg?: string;
    bgMediaId?: string;
  }): Promise<void> {

    // This.showSnackbar('Uploading Header Photo');
    const res = await PostService.uploadImage(eResp.orgBg, this.selectedbg[0]);
    if (res === HTTP_RESPONSE_CODE.HTTP_OK) {

      this.postService.updateUploaded(eResp.bgMediaId).subscribe(
        () => {

          this.brand._id = eResp._id;
          this.brand.headerImage._id = eResp.bgMediaId;
          this.showSnackbar('Header Photo uploaded');

        }, () => {

          this.showSnackbar('Header Photo not uploaded!');
          this.loading = false;

        }
      );

    } else {

      this.showSnackbar('Header Photo not uploaded!');
      this.loading = false;

    }

  }

  async uploadPhotos(resp: {
    _id: string;
    org?: string;
    mediaId?: string;
    orgBg?: string;
    bgMediaId?: string;
  }): Promise<void> {

    if (this.selectedLogo) {

      delete this.brand.logo._id;
      await this.uploadPopupFile(resp);

    }
    if (this.selectedbg) {

      delete this.brand.headerImage._id;
      await this.uploadBgFile(resp);

    }

  }

  cancelRenewal(): void {

    this.loading = true;
    this.brandService.cancelRenewal(this.appData.brand._id).subscribe(
      () => {

        this.loading = false;
        this.brand.autoRenew = false;

      }, (error) => {

        console.error(error);
        this.loading = false;

      }
    );

  }

  saveUpdatebrand(pro?: boolean): void {

    this.loading = true;
    this.mediaCheck();
    if (this.appData.brand) {

      this.brandService.edit(this.brand, this.appData.brand._id).subscribe(
        async (resp) => {

          await this.uploadPhotos(resp);
          this.loading = false;
          this.dialogRef.close(true);

        }, (error) => {

          console.error(error);
          this.loading = false;

        }
      );

    } else {

      this.brandService.save(this.brand, pro).subscribe(
        async (resp) => {

          await this.uploadPhotos(resp);
          if (pro) {

            this.payPro(true, resp._id);

          } else {

            this.dialogRef.close(true);

          }
          this.loading = false;

        }, (error) => {

          console.error(error);
          this.loading = false;

        }
      );

    }

  }

  reset(): void {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title: 'Reset branding to default?',
        reset: true,
        note: '' },
      width: '100%',
      height: '160px',
      maxWidth: '280px',
    });

    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.loading = true;
        this.brandService
          .reset(this.brand.createdBy.toString(), this.appData.brand._id)
          .subscribe(
            () => {

              this.loading = false;
              this.dialogRef.close(true);

            }, (error) => {

              console.error(error);
              this.loading = false;

            }
          );

      }

    });

  }

  payPro(activatePro?: boolean, brandId?: string): void {

    if (this.appData.isAdmin) {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Upgrade Branding with gifts?',
          note: 'One year subscription will be activated!',
        },
        width: '100%',
        height: '160px',
        maxWidth: '280px',
      });

      dialogRef.afterClosed().subscribe((result) => {

        if (result) {

          this.loading = true;
          this.brandService.upgradePro(this.appData.user).subscribe(
            () => {

              this.brandService.get(this.appData.user).subscribe(
                (brand) => {

                  this.brand.autoRenew = brand.autoRenew;
                  this.brand.code = brand.code;
                  this.brand.subscriptionEndDate = brand.subscriptionEndDate;
                  this.brand.subscriptionStartDate =
                    brand.subscriptionStartDate;
                  if (activatePro) {

                    this.dialogRef.close(true);

                  } else {

                    this.appData.brand.code = brand.code;
                    this.appData.brand.subscriptionEndDate =
                      brand.subscriptionEndDate;
                    this.appData.brand.subscriptionStartDate =
                      brand.subscriptionStartDate;

                  }
                  this.loading = false;

                }, (error) => {

                  console.error(error);
                  this.loading = false;

                }
              );

              /*
               * This.loading = false;
               * this.dialogRef.close(true);
               */

            }, (error) => {

              console.error(error);
              this.loading = false;

            }
          );

        } else if (activatePro) {

          this.dialogRef.close(true);

        }

      });
      return;

    }
    const locationArrray = location.toString().split('?');
    this.paymentTab = open(
      locationArrray[1] ? `${locationArrray[0]}/redirect?${locationArrray[1]}` : `${locationArrray[0]}/redirect`, '_blank'
    );
    this.loading = true;
    this.paymentService
      .proSubscriptionSession(activatePro ? brandId : this.appData.brand._id)
      .subscribe(
        (value) => {

          this.loading = false;
          this.checkStatusPro(activatePro, brandId);
          localStorage.setItem('stripeSubsUrl', value.url);
          this.listenWindowClose();

        }, (error) => {

          console.error(error);
          this.loading = false;

        }
      );

  }

  checkStatusPro(activatePro?: boolean, brandId?: string): void {

    this.loading = true;
    this.statusSubs = interval(3 * 1000)
      .pipe(
        flatMap(() =>
          this.paymentService.proSubscriptionStatus(
            activatePro ? brandId : this.appData.brand._id
          )
        )
      )
      .subscribe((values) => {

        if (values.status === 'success') {

          this.statusSubs?.unsubscribe();
          timer(8000).subscribe(() => {

            this.paymentTab?.close();
            localStorage.removeItem('stripeSubsUrl');

          });
          this.brandService.get(this.appData.user).subscribe(
            (brand) => {

              this.brand.code = brand.code;
              this.brand.autoRenew = true;
              this.brand.subscriptionEndDate = brand.subscriptionEndDate;
              this.brand.subscriptionStartDate = brand.subscriptionStartDate;
              if (activatePro) {

                this.dialogRef.close(true);

              } else {

                this.appData.brand.code = brand.code;
                this.appData.brand.subscriptionEndDate =
                  brand.subscriptionEndDate;
                this.appData.brand.subscriptionStartDate =
                  brand.subscriptionStartDate;

              }
              this.loading = false;

            }, (error) => {

              console.error(error);
              this.loading = false;

            }
          );

          /*
           * This.loading = false;
           * This.dialogRef.close(true);
           */

        } else if (values.status === 'cancelled') {

          this.statusSubs?.unsubscribe();
          timer(5000).subscribe(() => {

            this.paymentTab?.close();
            localStorage.removeItem('stripeSubsUrl');

          });
          if (activatePro) {

            this.dialogRef.close(true);

          }
          this.loading = false;
          // This.dialogRef.close(false);

        }

      });

  }

  pay(): void {

    if (this.appData.isAdmin) {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Upgrade Branding?',
          note: 'One year subscription will be activated!',
        },
        width: '100%',
        height: '160px',
        maxWidth: '280px',
      });

      dialogRef.afterClosed().subscribe((result) => {

        if (result) {

          this.loading = true;
          this.brandService.upgrade(this.appData.user).subscribe(
            () => {

              this.brandService.get(this.appData.user).subscribe(
                (brand) => {

                  this.brand.autoRenew = brand.autoRenew;
                  this.brand.code = brand.code;
                  this.brand.subscriptionEndDate = brand.subscriptionEndDate;
                  this.brand.subscriptionStartDate =
                    brand.subscriptionStartDate;
                  this.appData.brand.code = brand.code;
                  this.appData.brand.subscriptionEndDate =
                    brand.subscriptionEndDate;
                  this.appData.brand.subscriptionStartDate =
                    brand.subscriptionStartDate;
                  this.loading = false;

                }, (error) => {

                  console.error(error);
                  this.loading = false;

                }
              );

              /*
               * This.loading = false;
               * this.dialogRef.close(true);
               */

            }, (error) => {

              console.error(error);
              this.loading = false;

            }
          );

        }

      });
      return;

    }
    const locationArrray = location.toString().split('?');
    this.paymentTab = open(
      locationArrray[1] ? `${locationArrray[0]}/redirect?${locationArrray[1]}` : `${locationArrray[0]}/redirect`, '_blank'
    );
    this.loading = true;
    this.paymentService.subscriptionSession(this.appData.brand._id).subscribe(
      (value) => {

        this.loading = false;
        this.checkStatus();
        localStorage.setItem('stripeSubsUrl', value.url);
        this.listenWindowClose();

      }, (error) => {

        console.error(error);
        this.loading = false;

      }
    );

  }

  listenWindowClose(): void {

    timer(3000).subscribe(() => {

      if (this.paymentTab.closed) {

        localStorage.removeItem('stripeSubsUrl');
        this.paymentService
          .cancelSubscription(this.appData.brand._id)
          .subscribe(
            () => {}, (error) => {

              console.error(error);

            }
          );

      } else {

        this.listenWindowClose();

      }

    });

  }

  checkStatus(): void {

    this.loading = true;
    this.statusSubs = interval(3 * 1000)
      .pipe(
        flatMap(() =>
          this.paymentService.subscriptionStatus(this.appData.brand._id)
        )
      )
      .subscribe((values) => {

        if (values.status === 'success') {

          this.statusSubs?.unsubscribe();
          timer(8000).subscribe(() => {

            this.paymentTab?.close();
            localStorage.removeItem('stripeSubsUrl');

          });
          this.brandService.get(this.appData.user).subscribe(
            (brand) => {

              this.brand.code = brand.code;
              this.brand.autoRenew = true;
              this.brand.subscriptionEndDate = brand.subscriptionEndDate;
              this.brand.subscriptionStartDate = brand.subscriptionStartDate;
              this.appData.brand.code = brand.code;
              this.appData.brand.subscriptionEndDate =
                brand.subscriptionEndDate;
              this.appData.brand.subscriptionStartDate =
                brand.subscriptionStartDate;
              this.loading = false;

            }, (error) => {

              console.error(error);
              this.loading = false;

            }
          );

          /*
           * This.loading = false;
           * This.dialogRef.close(true);
           */

        } else if (values.status === 'cancelled') {

          this.statusSubs?.unsubscribe();
          timer(5000).subscribe(() => {

            this.paymentTab?.close();
            localStorage.removeItem('stripeSubsUrl');

          });
          this.loading = false;
          // This.dialogRef.close(false);

        }

      });

  }

  ngOnDestroy(): void {

    this.statusSubs?.unsubscribe();

  }

  preview(): void {

    this.dialog.open(PreviewSelectComponent, {
      data: { logo: this.logo },
      width: '100%',
      maxWidth: '175px',
      disableClose: true,
      panelClass: 'preview-brand',
    });

  }

  showUrl(content: string): string {

    return `${location.href
      .replace(this.router.url, '')
      .replace('https://', '')}?brand=${content}`;

  }

  copyEventLink(content: string, message: string): void {

    const domain = location.href.replace(this.router.url, '');
    this.showSnackbar(message);
    navigator.clipboard
      .writeText(`${domain}?brand=${content}`)
      .then()
      ['catch']((error) => console.error(error));

  }

  selectFile = (event: Event, type: 'logo' | 'bg'): void => {

    this.inputChange = true;
    const fileTag = <HTMLInputElement>event.target;
    // Type === 'logo' ? this.selectedLogo = fileTag.files : this.selectedbg = fileTag.files;
    if (fileTag.files[0].name.toLowerCase().includes('.heic')) {

      heic2any({
        blob: fileTag.files[0],
        toType: 'image/jpeg',
      }).then((conversionResult) => {

        const reader = new FileReader();
        reader.readAsDataURL(<Blob>conversionResult);
        reader.onloadend = () => {

          // Type === 'logo' ? this.logo = reader.result.toString() : this.header = reader.result.toString();
          this.logoChange = type === 'logo';

          const dialogRef = this.dialog.open(CropHeaderComponent, {
            data: {
              image: reader.result.toString(),
              aspectRatio: 1 / 1,
              maintainAspectRatio: false,
              name: fileTag.files[0].name,
            },
            disableClose: true,
            width: '100%',
            maxWidth: '400px',
            panelClass: 'plan-dialog',
          });
          dialogRef.afterClosed().subscribe((value) => {

            if (value) {

              type === 'logo' ? this.selectedLogo = value.file : this.selectedbg = value.file;
              type === 'logo' ? this.logo = value.image : this.header = value.image;

            }

          });

        };

      });
      return;

    }
    const reader = new FileReader();
    reader.readAsDataURL(fileTag.files[0]);
    reader.onload = () => {

      // Type === 'logo' ? this.logo = reader.result.toString() : this.header = reader.result.toString();
      this.logoChange = type === 'logo';

      const dialogRef = this.dialog.open(CropHeaderComponent, {
        data: {
          image: reader.result.toString(),
          aspectRatio: 1 / 1,
          maintainAspectRatio: false,
          name: fileTag.files[0].name,
        },
        disableClose: true,
        width: '100%',
        maxWidth: '400px',
        panelClass: 'plan-dialog',
      });
      dialogRef.afterClosed().subscribe((value) => {

        if (value) {

          type === 'logo' ? this.selectedLogo = value.file : this.selectedbg = value.file;
          type === 'logo' ? this.logo = value.image : this.header = value.image;

        }

      });

    };

  };

  displayFirstValidity(): Date {

    const NinetyDays = 90;
    const date = new Date(
      new Date().setUTCDate(new Date().getDate() + NinetyDays)
    );
    return date;

  }

}
