import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Brand } from '@liveshare/entity/Brand';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BASE_URI, BRAND_API } from '@liveshare/serverAPI';
import { User } from '@liveshare/entity/User';
import { environment } from '@lsenvironments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandService {

  constructor(private readonly http: HttpClient) {}

  save(
    brand: Brand,
    pro?: boolean
  ): Observable<{
    _id: string;
    org: string;
    mediaId: string;
    orgBg: string;
    bgMediaId: string;
  }> {

    return this.http
      .post<{
        _id: string;
        org: string;
        mediaId: string;
        orgBg: string;
        bgMediaId: string;
      }>(`${environment.serverUrl}${BASE_URI}${BRAND_API}`, { pro,
        ...brand })
      .pipe(catchError((err) => throwError(err)));

  }

  list(): Observable<Array<Brand>> {

    return this.http
      .get<Array<Brand>>(`${environment.serverUrl}${BASE_URI}${BRAND_API}/list/all`)
      .pipe(catchError((err) => throwError(err)));

  }

  brandReport(skip: number): Observable<Array<Brand>> {

    return this.http
      .post<Array<Brand>>(`${environment.serverUrl}${BASE_URI}${BRAND_API}/list/report`, { skip })
      .pipe(catchError((err) => throwError(err)));

  }

  getByCode(brandCode: string): Observable<Brand> {

    return this.http
      .get<Brand>(`${environment.serverUrl}${BASE_URI}${BRAND_API}/${brandCode}`)
      .pipe(catchError((err) => throwError(err)));

  }

  get(user?: User): Observable<Brand> {

    return this.http
      .post<Brand>(`${environment.serverUrl}${BASE_URI}${BRAND_API}/get`, user || {})
      .pipe(catchError((err) => throwError(err)));

  }

  getCode(user: User): Observable<{ code: string }> {

    return this.http
      .post<{ code: string }>(`${environment.serverUrl}${BASE_URI}${BRAND_API}/code`, user)
      .pipe(catchError((err) => throwError(err)));

  }

  upgrade(user: User): Observable<{ message: string }> {

    return this.http
      .post<{ message: string }>(`${environment.serverUrl}${BASE_URI}${BRAND_API}/upgrade`, user)
      .pipe(catchError((err) => throwError(err)));

  }

  disable(user: User): Observable<{ message: string }> {

    return this.http
      .post<{ message: string }>(`${environment.serverUrl}${BASE_URI}${BRAND_API}/disable`, user)
      .pipe(catchError((err) => throwError(err)));

  }

  upgradePro(user: User): Observable<{ message: string }> {

    return this.http
      .post<{ message: string }>(`${environment.serverUrl}${BASE_URI}${BRAND_API}/upgrade-pro`, user)
      .pipe(catchError((err) => throwError(err)));

  }

  edit(
    brand: Brand,
    brandId: string
  ): Observable<{
    _id: string;
    org: string;
    mediaId: string;
    orgBg: string;
    bgMediaId: string;
  }> {

    return this.http
      .put<{
        _id: string;
        org: string;
        mediaId: string;
        orgBg: string;
        bgMediaId: string;
      }>(`${environment.serverUrl}${BASE_URI}${BRAND_API}/${brandId}`, brand)
      .pipe(catchError((err) => throwError(err)));

  }

  reset(userId: string, brandId: string): Observable<{ message: string }> {

    return this.http
      .put<{ message: string }>(`${environment.serverUrl}${BASE_URI}${BRAND_API}/${brandId}/reset`, {
        _id: userId,
      })
      .pipe(catchError((err) => throwError(err)));

  }

  cancelRenewal(brandId: string): Observable<{ message: string }> {

    return this.http
      .put<{ message: string }>(
        `${environment.serverUrl}${BASE_URI}${BRAND_API}/${brandId}/cancel-renewal`, {}
      )
      .pipe(catchError((err) => throwError(err)));

  }

  deleteBrand(brandId: string): Observable<{ message: string }> {

    return this.http['delete']<{ message: string }>(
      `${environment.serverUrl}${BASE_URI}${BRAND_API}/${brandId}`
    ).pipe(catchError((err) => throwError(err)));

  }

}
