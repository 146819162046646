import { Injectable } from '@angular/core';
import { BrandRepository } from './states/brand.repository';

@Injectable()
export class AppInitService {

  constructor(private brandRepo: BrandRepository) {}

  async init() {

    await this.brandRepo.load();

  }

}
