<div
  [class.overlay]="loading"
  style="z-index: 9999"
  [class.spinner]="loading"
  (click)="$event.stopPropagation()"
></div>
<div>
  <div>
    <h1 class="w-full flex items-center">
      <span>Branding</span>
      <button
        class="btn btn-circle btn-ghost ml-auto"
        [mat-dialog-close]="false"
      >
        <mat-icon>close</mat-icon>
      </button>
    </h1>

    <div style="padding: 0px 20px; margin-bottom: 10px">
      Add your logo to the Photo Share app
    </div>
    <div
      class="d-flex align-items-center"
      style="padding: 15px 20px; justify-content: space-around"
    >
      <div *ngIf="!logo" style="max-width: 50%">
        <img
          style="height: 80px; object-fit: contain; max-width: 100%"
          [src]="defaultLogo"
          alt=""
        />
      </div>
      <div *ngIf="logo" style="max-width: 50%">
        <img
          style="
            height: 80px;
            object-fit: contain;
            max-width: 100%;
            background-color: white;
          "
          #logoImage
          (load)="
            logoImage.style.display = 'block'; logoRetry.style.display = 'none'
          "
          (error)="
            logoRetry.style.display = 'flex';
            logoImage.style.display = 'none';
            logoImage.src = logo
          "
          [src]="logo"
        />
        <span
          #logoRetry
          style="
            display: none;
            justify-content: center;
            margin: 5px 0px 5px 0px;
          "
        >
          <div class="retry"></div>
        </span>
      </div>
      <div class="d-flex" style="flex-direction: column">
        <div style="font-size: 10px; text-align: center">
          Upload New Logo<br />Image (1024 x 410)
        </div>
        <div class="d-flex justify-content-between">
          <input
            id="logoImg"
            #logoImg
            style="display: none"
            type="file"
            (change)="selectFile($event, 'logo')"
            accept="image/png,image/jpeg,image/jpg,.heic,.heif"
          />
          <button class="btn btn-circle btn-ghost" (click)="logoImg.click()">
            <mat-icon>cloud_upload</mat-icon>
          </button>
          <button class="btn btn-circle btn-ghost" (click)="preview()">
            <mat-icon
              [style.color]="!logo ? 'grey' : 'black'"
              [style.pointer-events]="!logo ? 'none' : 'all'"
              >remove_red_eye</mat-icon
            >
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex btn-section" style="justify-content: space-around">
    <div
      (click)="saveUpdatebrand()"
      *ngIf="
        (brand?.logo && logoChange) ||
        (!brand?.logo && brand?.code && logoChange)
      "
      style="margin-right: 0px"
      class="btn"
    >
      UPDATE
    </div>
    <div
      (click)="reset()"
      *ngIf="brand?.logo && !logoChange"
      style="margin-right: 0px"
      class="btn"
    >
      RESET
    </div>
    <!-- brand?.logo && -->
    <div
      *ngIf="brand?.code"
      (click)="openQrcodePopup(brand.code)"
      style="margin-right: 0px"
      class="btn"
    >
      SHARE
    </div>

    <div
      (click)="disable()"
      *ngIf="appData.hasDisableOption && showDisableBtn()"
      style="margin-right: 0px"
      class="btn"
    >
      DISABLE
    </div>
  </div>

  <div style="padding: 10px" class="d-flex justify-content-center">
    <div style="flex-direction: column" class="d-flex align-items-center">
      <div
        *ngIf="!brand?.code"
        (click)="saveUpdatebrand()"
        [disabled]="!(logoChange || (!brand?.code && brand?.logo))"
        class="btn"
      >
        ACTIVATE
      </div>
      <div *ngIf="!brand?.code" style="text-align: center">
        Free - Valid until {{ displayFirstValidity() | date }}<br />
        Extend for 1 year - $79.99
      </div>
    </div>
    <div style="flex-direction: column" class="d-flex align-items-center">
      <!-- brand?.logo &&  -->
      <div *ngIf="brand?.code">
        Branding enabled through: {{ brand.subscriptionEndDate | date }}
      </div>
      <!-- brand?.logo &&  -->
      <div
        *ngIf="brand?.code"
        (click)="pay()"
        style="margin: 6px 0px"
        class="btn"
      >
        EXTEND
      </div>
      <!-- brand?.logo &&  -->
      <div *ngIf="brand?.code" style="text-align: center">
        EXTEND additional year - $79.99
      </div>
    </div>
  </div>

  <div style="padding: 10px" class="d-flex justify-content-center">
    <div style="flex-direction: column" class="d-flex align-items-center">
      <div
        *ngIf="!brand?.code"
        (click)="saveUpdatebrand(true)"
        style="
          width: 70%;
          text-align: center;
          line-height: 15px;
          display: block;
          width: 240px;
          margin-right: 0px;
          padding-top: 6px;
        "
        [disabled]="!(logoChange || (!brand?.code && brand?.logo))"
        class="btn"
      >
        ACTIVATE with Gifts
        <br /><span> Save $130 </span>
      </div>
      <div *ngIf="!brand?.code" style="text-align: center; margin-bottom: 8px">
        $350 - includes a “Premium Gifting 10 Pack” <br />
        and 1 year Branding.
      </div>
      <!-- brand?.logo &&  -->
      <div
        *ngIf="brand?.code"
        (click)="payPro()"
        style="width: 240px; display: block; padding-top: 6px"
        class="btn"
      >
        Extend with Gifts
        <br /><span> Save $130 </span>
      </div>
      <!-- brand?.logo &&  -->
      <div *ngIf="brand?.code" style="text-align: center; margin-bottom: 8px">
        $350 - includes a “Pro Pack” of 10 giftable <br />
        Premium events and 1 additional year of Branding.
      </div>
    </div>
  </div>
</div>
