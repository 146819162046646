import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BASE_URI, POST_API } from '@liveshare/serverAPI';
import { Post } from '@liveshare/entity/Post';
import { environment } from '@lsenvironments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {

  constructor(private readonly http: HttpClient) {}

  getEventPosts(
    eventLink: string,
    processedAt = '',
    passCode = ''
  ): Observable<{
    posts: Array<Post>;
    processedAt: string;
    eventId: string;
    slideShowConfig: Record<string, any>;
    liveView: boolean;
    status: string;
    plan: string;
    message: string;
  }> {

    const headers = new HttpHeaders().set('lspasscode', passCode);

    return this.http
      .get<{
        posts: Array<Post>;
        processedAt: string;
        eventId: string;
        liveView: boolean;
        slideShowConfig: Record<string, any>;
        status: string;
        plan: string;
        message: string;
      }>(
        `${environment.serverUrl}${BASE_URI}${POST_API}/${eventLink}/live?startingFrom=${processedAt}`, { headers }
      )
      .pipe(catchError((err) => throwError(err)));

  }

}
