export const HTTP_RESPONSE_CODE = {
  HTTP_OK: 200,
  HTTP_BAD_REQUEST: 400,
  FORBIDDEN_USER: 401,
  INTERNAL_SERVER_ERROR: 500,
  HTTP_NOT_FOUND: 404,
  HTTP_REDIRECT: 301,
};

// Should not give underscore _ in header key. It might cause while we deal with reverse proxy.
export const ADMIN_EMAIL = 'admin@livesharenow.com';
export const ACCESS_TOKEN_ID = 'ACCESSTOKEN';
export const GUEST_NAME = 'GUESTNAME';
export const GUEST_URL = 'GUESTURL';
export const MEDIA_LINK = '/api/medias/';
export const DEFAULT_BG_PATH = 'default/background';
export const DEFAULT_POPUP_PATH = 'default/popup';
export const DEFAULT_MEDIA_PATH = 'default/media';
export const CROPPED_MEDIA_PATH = 'cropped/media';
export const eventName = '<Event Name>';
export const eventDate = '<Event Date>';
export const linkName = '<Link Name>';
export const personalLink = '<Personal Link>';
export const firstName = '<First Name>';
export const primary = '<Primary>';
export const others = '<Others>';
export const EMAIL_VARIABLES = [
  eventName,
  eventDate,
  linkName,
  personalLink,
  firstName,
  primary,
  others,
];
export const unlockDate = ' <Unlock Date>';
export const KEEP_SAKE_VARIABLES = [ unlockDate ];
export const name = '<NAME>';
export const from = '<FROM>';

export const brand = '<Brand ID>';

export const hostName = '<HOST NAME>';
export const guestName = '<GUEST NAME>';

export const address = '<Address>';

export const trackingId = '<Tracking ID>';
export const orderDate = '<Order Date>';
export const fromName = '<From Name>';
export const toFirstName = '<To First Name>';
export const toLastName = '<To Last Name>';

export const close = 'CLOSE';
export const personalize = 'PERSONALIZE';
export const upgrade = 'UPGRADE';
export const headerImage = '<Header Image>';
export const brandLogo = '<Branding Logo>';
export const popupImage = '<Welcome Pop Up>';

export interface DeviceDetails {
  deviceId: string;
  MAC: string;
}

export const MEDIA_UPLOAD_PROGRESS = 'Uploading';
export const MEDIA_UPLOAD_SUCCESS = 'Uploaded';

export const INTERRUPT_CHECK_INTERVAL = 7000;
export const PLAYER_REFRESH_INTERVAL = 7000;

export const PLAN_CODES = {
  BASIC: 'Basic',
  PERSONALIZED: 'Personalized',
  PREMIUM: 'Premium',
  PREMIUMPLUS: 'PremiumPlus',
  PREMIUMWITHPLAYER: 'PremiumWithPlayer',
};
