import { Component, Injector, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginSignupDialogComponent } from './login-signup-dialog/login-signup-dialog.component';
import { CommunicationService } from '@lsservices/communication.service';
import { LoginSignupService } from '@lsservices/login-signup.service';
import { VerifyComponent } from './verify/verify.component';
import { BrandService } from '@lsservices/brand.service';
import { BASE_URI, BRAND_API } from '@liveshare/serverAPI';
import { AppAssetService } from '@lsservices/app-asset.service';
import { environment } from '@lsenvironments/environment';

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: [ './login-signup.component.scss' ],
})
export class LoginSignupComponent implements OnInit {

  tempLinkName: string;

  postId: string;

  singlePostId: string;

  bgUrl: string;

  newTab = false;

  userId: string;

  logo: string;

  imgDomain: string;

  queryParams: Record<string, unknown>;

  logoLoading = true;

  private dialogRef = null;

  dataJ: { preview: boolean; image: string };

  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  loading = false;

  defaultLogo = '';

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    private injector: Injector,
    private brandService: BrandService,
    private appAssetService: AppAssetService,
    private readonly communicationService: CommunicationService,
    private readonly loginSignupService: LoginSignupService
  ) {

    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dataJ = this.injector.get(MAT_DIALOG_DATA, null);

  }

  findBg(): void {

    const subDomain = window.location.href.replace(this.router.url, '');
    if (subDomain.toLowerCase().includes('pets.livesharenow.com')) {

      // Linear-gradient(to right, rgb(0 0 0 / 0.5), rgb(0 0 0 / 0.5)),
      this.bgUrl = 'url(\'/assets/images/landing-bg.png\')';

    } else {

      // Linear-gradient(to right, rgb(0 0 0 / 0.5), rgb(0 0 0 / 0.5)),
      this.bgUrl = 'url(\'/assets/images/landing-bg_o.png\')';

    }

  }

  randName(length: number): string {

    let result = '';
    const charactersLength = this.characters.length;
    for (let initial = 0; initial < length; initial++) {

      result += this.characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );

    }
    return result;

  }

  ngOnInit(): void {

    this.loading = true;
    this.appAssetService.get().subscribe(
      (app) => {

        if (
          app && app?.landingBg
        ) {

          // Linear-gradient(to right, rgb(0 0 0 / 0.5), rgb(0 0 0 / 0.5)),
          this.bgUrl = `url('${app?.landingBg}')`;

        } else {

          this.findBg();

        }
        if (app && app?.logo) {

          this.defaultLogo = `${app?.logo}`;

        } else {

          this.defaultLogo = '/assets/images/ls-logo.png';

        }
        this.loading = false;

      }, (error) => {

        this.loading = false;
        console.error(error);
        this.findBg();
        this.defaultLogo = '/assets/images/ls-logo.png';

      }
    );
    this.route.queryParams.subscribe((params) => {

      this.queryParams = params;
      this.getlogo();

    });
    this.tempLinkName = this.route.snapshot.paramMap.get('linkName');
    const eventId = this.route.snapshot.paramMap.get('eventId');
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.postId = this.route.snapshot.paramMap.get('id');
    this.singlePostId = this.route.snapshot.paramMap.get('postId');
    if (
      this.router.url.includes(`/event/${this.tempLinkName}`) ||
      this.router.url.includes(`/event/${this.tempLinkName}/`) ||
      this.router.url.includes(`/event/${this.tempLinkName}/event-detail/${eventId}/view/${this.postId}/join`) ||
      this.router.url.includes(`/event/${this.tempLinkName}/event-detail/${eventId}/view/${this.postId}/join/`) ||
      this.router.url.includes(`/event/${this.tempLinkName}/event-detail/${eventId}/view/${this.postId}/single/${this.singlePostId}/join`) ||
      this.router.url.includes(`/event/${this.tempLinkName}/event-detail/${eventId}/view/${this.postId}/single/${this.singlePostId}/join/`)
    ) {

      this.communicationService.setGuestUrl(window.location.href);
      this.openDialog('join', false);

    } else {

      this.tempLinkName = '';
      // This.openDialog('event');

    }
    this.routePopup();

  }

  getlogo(): void {

    this.logoLoading = true;
    if (this.dataJ?.preview) {

      this.logo = this.dataJ.image;
      this.logoLoading = false;
      return;

    }
    let brand;
    if (this.queryParams.brand) {

      brand = `${this.queryParams.brand}`;

    } else if (localStorage.getItem('brandCode')) {

      brand = localStorage.getItem('brandCode');

    } else {

      this.logoLoading = false;
      return;

    }
    this.brandService.getByCode(brand).subscribe(
      (value) => {

        if (value && value?.code) {

          this.imgDomain = `${environment.serverUrl}${BASE_URI}${BRAND_API}/`;
          this.logo = `${this.imgDomain}${value.code}/org/logo/${this.randName(
            3
          )}`;

        }
        this.logoLoading = false;

      }, (error) => {

        console.error(error);
        this.logoLoading = false;

      }
    );

  }

  routePopup(): void {

    if (this.router.url.startsWith('/join')) {

      this.newTab = true;
      this.openDialog('join', false);

    }
    if (this.router.url.startsWith('/manage')) {

      this.newTab = true;
      this.openDialog('event', false);

    }
    if (this.router.url.startsWith('/create')) {

      this.newTab = true;
      this.openDialog('event', true);

    }
    if (this.router.url.startsWith('/verify')) {

      this.newTab = true;
      this.openVerifyDialog();

    }
    if (this.router.url.startsWith('/brand')) {

      this.newTab = true;
      this.communicationService.setOpenBranding(true);
      this.openDialog('event', false);

    }

  }

  openVerifyDialog(): void {

    this.dialog.open(VerifyComponent, {
      data: {
        userId: this.userId,
      },
      maxWidth: '400px',
      disableClose: true,
    });

  }

  openNewWindow(path: string): void {

    if (this.dataJ?.preview) {

      return;

    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree([ `/${path}` ], { queryParams: this.queryParams })
    );
    window.open(url, '_self');

  }

  openDialog(type: string, create: boolean): void {

    const dialogRef = this.dialog.open(LoginSignupDialogComponent, {
      data: {
        isJoin: type === 'join',
        createEvent: create,
        linkName: this.tempLinkName,
        postId: this.postId,
        single: this.singlePostId,
        remember: false,
        newTab: this.newTab,
      },
      disableClose: true,
      width: '100%',
      maxWidth: '480px',
      height: '100%',

      /*
       * HasBackdrop: true,
       * panelClass: 'dialog-bottom-panel-initial',
       * panelClass: 'dialog-bottom-panel',
       */
      panelClass: 'dialog-bottom-panel-top-fill',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Location.reload();
    });

  }

}
