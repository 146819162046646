<div *ngIf="!loading" class="main-div">
  <span class="message">
    {{ message }}
  </span>
  <button
    *ngIf="!userData"
    class="deleteBtn"
    mat-button
    mat-dialog-close
    (click)="routeTo()"
  >
    OK
  </button>
  <div
    *ngIf="userData"
    style="display: flex; width: 100%; justify-content: space-between"
  >
    <button
      class="deleteBtn"
      style="width: 175px; background: gray"
      mat-button
      mat-dialog-close
    >
      Close
    </button>
    <button
      class="deleteBtn"
      style="width: 175px"
      mat-button
      (click)="resendEmail()"
    >
      Resend Activation Link
    </button>
  </div>
</div>
<div *ngIf="loading" class="retry"></div>
