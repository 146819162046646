import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { timer } from 'rxjs';

@Component({
  selector: 'app-crop-header',
  templateUrl: './crop-header.component.html',
  styleUrls: [ './crop-header.component.scss' ],
})
export class CropHeaderComponent {

  loading = false;

  aspectRatio: number;

  canvasRotation = 0;

  maintainAspectRatio = true;

  croppedImageBase = '';

  croppedImageFile: File;

  imageBase64 = '';

  format = 'jpeg';

  constructor(
    private readonly snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CropHeaderComponent>,
    @Inject(MAT_DIALOG_DATA)
    public appData: {
      image: string;
      edit?: boolean;
      postImage?: boolean;
      maintainAspectRatio?: boolean;
      aspectRatio: number;
      name: string;
    },
    private http: HttpClient
  ) {

    this.loading = true;
    this.format = this.appData.name.includes('.png') ? 'png' : this.appData.name.split('.')[1];
    this.aspectRatio = this.appData.aspectRatio;
    this.maintainAspectRatio = Object.keys(this.appData)?.includes(
      'maintainAspectRatio'
    ) ? this.appData?.maintainAspectRatio : !this.appData.postImage;

    if (this.appData.image?.startsWith('http')) {

      this.http.get(this.appData.image, {responseType: 'blob'}).subscribe((image: any) => {

        const reader = new FileReader();
        reader.onloadend = () => {

          // Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...

          // Convert to Base64 string
          const base64 = reader.result;
          this.imageBase64 = base64 as string;
          // Logs wL2dvYWwgbW9yZ...

        };
        reader.readAsDataURL(image);

      });

    } else {

      this.imageBase64 = `${this.appData.image}`;

    }

  }

  imageCropped(event: ImageCroppedEvent): void {

    this.loading = false;
    this.croppedImageBase = event.base64;
    this.croppedImageFile = this.dataURLtoFile(
      event.base64, this.appData.name.replace(/heic/giu, 'jpeg')
    );

  }

  reset(): void {

    this.imageBase64 = '';
    timer(500).subscribe(() => {

      this.imageBase64 = `${this.appData.image}`;

    });

  }

  changeAspect(ratio: number): void {

    this.aspectRatio = ratio;
    this.maintainAspectRatio = true;

  }

  dataURLtoFile(dataurl: string, filename: string): File {

    this.loading = false;
    const arr = dataurl.split(',');
    // eslint-disable-next-line prefer-named-capture-group
    const mime = `${arr[0].match(/:(.*?);/u)[1]}`;
    const bstr = atob(arr[1]);
    let lngth = bstr.length;
    const u8arr = new Uint8Array(lngth);

    while (lngth--) {

      u8arr[lngth] = bstr.charCodeAt(lngth);

    }

    return new File([ u8arr ], filename, { type: mime });

  }

  loadImageFailed(): void {

    this.loading = false;
    this.showSnackbar('Unable to crop!');

  }

  showSnackbar(message: string): void {

    this.snackBar.open(message, '', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });

  }

  close(): void {

    this.dialogRef.close({
      image: this.croppedImageBase,
      file: [ this.croppedImageFile ],
    });

  }

}
