import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {

  static MatchValidator(source: string, target: string): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value ? { mismatch: true } : null;

    };

  }

  static ExactMatchValidator(source: string, match: string): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

      const sourceCtrl = control.get(source);

      return sourceCtrl && sourceCtrl.value !== match ? { mismatch: true } : null;

    };

  }

}
