import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { Post, PostS } from '@liveshare/entity/Post';
import { PostService } from './post.service';
import { HTTP_RESPONSE_CODE } from '@liveshare/Constants';
import { User } from '@liveshare/entity/User';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LoginSignupDialogComponent } from '../login-signup/login-signup-dialog/login-signup-dialog.component';
import { Router } from '@angular/router';
/* eslint-disable no-loop-func */

const timeout = 1000;
@Injectable({
  providedIn: 'root',
})
export class PhotoUpload {

  constructor(
    private postService: PostService,
    private readonly snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog
  ) {}

  finish = new BehaviorSubject(false);

  finished = this.finish.asObservable();

  imageCnt = new BehaviorSubject(0);

  imageCount = this.imageCnt.asObservable();

  private uploadingStatus = new BehaviorSubject('');

  status = this.uploadingStatus.asObservable();

  private allPostS = new BehaviorSubject<Array<Post>>([]);

  posts = this.allPostS.asObservable();

  showSnackbar(message: string): void {

    this.snackBar.open(message, '', {
      duration: 1000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });

  }

  upload(
    fileList: Array<File>,
    postS: PostS,
    linkName: string,
    others: { messageS: string; caption: string; tags: Array<string> },
    eventId: string,
    userS: User
  ): void {

    const allPost: Array<Post> = [];
    let index = -1;
    this.uploadingStatus.next('Uploading...');
    this.imageCnt.next(fileList.length);
    for (const file of fileList) {

      if (file.name.toLowerCase().includes('.heic')) {

        postS.media.type = 'Image';
        postS.media.name = file.name.toLowerCase().replace('.heic', '');
        postS.media.extension = 'heic';

      } else {

        postS.media.type =
          file.type.substring(0, file.type.indexOf('/')).toLowerCase() ===
          'image' ? 'Image' : 'Video';
        postS.media.extension = file.type
          .replace(postS.media.type === 'Image' ? 'image/' : 'video/', '')
          .toLowerCase();
        postS.media.name = file.name
          .toLowerCase()
          .replace('.jpg', '')
          .replace(`.${postS.media.extension}`, '');

      }
      postS.tags = others.tags;
      postS.type = 'Image';
      postS.message = others.messageS;
      postS.caption = others.caption;
      this.postService.savePost(postS, eventId).subscribe(
        async (message) => {

          if (message) {

            const res = await PostService.uploadImage(message.org, file);
            if (res === HTTP_RESPONSE_CODE.HTTP_OK) {

              this.postService.updateUploaded(message.mediaId).subscribe(
                () => {

                  const post = JSON.parse(JSON.stringify(postS));
                  post._id = message._id;
                  post.createdAt = new Date();
                  const user = JSON.parse(JSON.stringify(userS));
                  user.profileMedia = userS?.profileMedia?._id || '';
                  post.createdBy = user;
                  if (message.mediaId) {

                    post.media._id = message.mediaId;
                    post.media.uploadedAt = new Date();

                  }
                  allPost.push(post);
                  index++;
                  this.uploadingStatus.next(
                    `Uploaded (${index + 1}/${fileList.length})`
                  );
                  if (index === fileList.length - 1) {

                    this.statusFinish(true);
                    this.router.url.includes('/view/') ? this.allPostS.next(allPost) : this.allPostS.next([]);
                    !this.router.url.includes('/view/') &&
                    this.router.url.includes(eventId) ? this.finish.next(true) : this.finish.next(false);

                  }

                }, () => {

                  this.showSnackbar(`${postS.media.name} not uploaded!`);
                  if (index === fileList.length - 1 || index === -1) {

                    this.statusFinish();

                  }

                }
              );

            } else {

              this.showSnackbar(`${postS.media.name} not uploaded!`);
              if (index === fileList.length - 1 || index === -1) {

                this.statusFinish();

              }

            }

          }

        }, (error) => {

          console.error(error);
          if (index === fileList.length - 1 || index === -1) {

            this.statusFinish();

          }
          if (linkName) {

            this.openDialog('event');

          }

        }
      );

    }

  }

  statusFinish(posted?: boolean): void {

    timer(timeout).subscribe(() => {

      this.uploadingStatus.next('');
      if (posted) {

        this.showSnackbar('Photos posted');

      }

    });

  }

  openDialog(type: string): void {

    this.dialog.open(LoginSignupDialogComponent, {
      data: {
        isJoin: type === 'join',
        linkName: '',
        remember: true,
      },
      width: '100%',
      maxWidth: '480px',
      hasBackdrop: true,
      panelClass: 'dialog-bottom-panel',
      autoFocus: false,
    });

  }

}
