/* eslint-disable func-style */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '@liveshare/entity/User';
import { BrandService } from '@lsservices/brand.service';
import { UserService } from '@lsservices/user.service';
import { AddEditBrandComponent } from 'src/app/events/brand/add-edit-brand/add-edit-brand.component';
import { ConfirmationDialogComponent } from 'src/app/events/confirmation-dialog/confirmation-dialog.component';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Sort } from '@angular/material/sort';
import { EditComponent } from './edit/edit.component';

function compare(obj1: number | string, obj2: number | string, isAsc: boolean) {

  return (obj1 < obj2 ? -1 : 1) * (isAsc ? 1 : -1);

}
function compareDate(obj1: Date, obj2: Date, isAsc: boolean) {

  dayjs.extend(isSameOrBefore);
  const diff = dayjs(obj1).isSameOrBefore(dayjs(obj2));
  return (!diff ? -1 : 1) * (isAsc ? 1 : -1);

}
@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: [ './accounts.component.scss' ],
})
export class AccountsComponent implements OnInit, AfterViewInit {

  searchText = new UntypedFormControl('');

  loader = false;

  loading = false;

  displayedColumns: string[] = [
    'Date',
    'Email',
    'Host',
    'Events',
    'Type',
    'actions',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  accounts = new MatTableDataSource<User>([]);

  accountsCpy: Array<User> = [];

  constructor(
    private readonly userService: UserService,
    private readonly brandService: BrandService,
    public dialog: MatDialog
  ) {}

  ngAfterViewInit(): void {

    this.accounts.paginator = this.paginator;

  }

  ngOnInit(): void {

    this.loader = true;
    this.userService.listAccounts().subscribe(
      (accounts) => {

        this.accounts.data = accounts;
        this.accountsCpy = [ ...accounts ];
        this.loader = false;

      }, (error) => {

        console.error(error);
        this.loader = false;

      }
    );

  }

  // eslint-disable-next-line class-methods-use-this
  showDate(date: Date): string {

    if (!date) {

      return '--';

    }
    const dateS = dayjs(date).format('MMMM D, YYYY');
    return dateS;

  }

  sortData(sort: Sort): void {

    const dataS: any = this.accounts.data.slice();
    if (!sort.active || sort.direction === '') {

      this.accounts.data = [ ...dataS ];
      return;

    }

    this.accounts.data = [
      ...dataS.sort((obj1, obj2) => {

        const isAsc = sort.direction === 'asc';
        switch (sort.active) {

        case 'Host':
          return compare(obj1.name, obj2.name, isAsc);
        case 'Date':
          return compareDate(obj1.createdAt, obj2.createdAt, isAsc);
        case 'Email':
          return compare(obj1.email, obj2.email, isAsc);
        case 'Events':
          return compare(obj1.events, obj2.events, isAsc);
        default:
          return 0;

        }

      }),
    ];

  }

  removeUser(userId: string): void {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete this Account?',
        note: 'This action can not be undone, all event and photos will be deleted!',
        accountDelete: true,
      },
      width: '100%',
      height: '165px',
      maxWidth: '280px',
    });

    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.loading = true;
        this.userService.removeUserById(userId).subscribe(
          () => {

            this.loading = false;
            const accounts = this.accounts.data.filter(
              (obj) => obj._id !== userId
            );
            this.accounts.data = [ ...accounts ];
            this.accountsCpy = [ ...accounts ];

          }, (error) => {

            console.error(error);
            this.loading = false;

          }
        );

      }

    });

  }

  openEdit(user?: User): void {

    const ref = this.dialog.open(EditComponent, {
      data: { user },
      width: '100%',
      maxWidth: '400px',
      disableClose: true,
      panelClass: 'set-value',
    });

    ref.afterClosed().subscribe((value) => {

      if (value) {

        user.plan = value;

      } else {

        this.loading = false;

      }

    });

  }

  openBrand(user?: User): void {

    this.loading = true;
    this.brandService.get(user).subscribe(
      (brand) => {

        const ref = this.dialog.open(AddEditBrandComponent, {
          data: { brand,
            isAdmin: true,
            hasDisableOption: true,
            user },
          width: '100%',
          maxWidth: '400px',
          disableClose: true,
          panelClass: 'set-value',
        });

        ref.afterClosed().subscribe((value) => {

          if (value) {

            this.openBrand(user);

          } else {

            this.loading = false;

          }

        });

      }, (error) => {

        console.error(error);
        this.loading = false;

      }
    );

  }

  search(): void {

    if (this.searchText.value) {

      const accounts = this.accounts.data.filter(
        (obj) =>
          obj.email
            .toLowerCase()
            .includes(this.searchText.value.toLowerCase()) ||
          obj.name.toLowerCase().includes(this.searchText.value.toLowerCase())
      );
      this.accounts.data = [ ...accounts ];

    } else {

      this.accounts.data = [ ...this.accountsCpy ];

    }

  }

}
