import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@liveshare/entity/User';
import { UserService } from '@lsservices/user.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: [ './edit.component.scss' ],
})
export class EditComponent {

  loading = false;

  userPlan: any = { _id: '',
    plan: 'Basic' };

  planCodes = [
    'Basic',
    'Premium',
    'PremiumPlus',
    'CorporatePremium',
    'CorporatePremiumPlus',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public appData: { user: User },
    public dialogRef: MatDialogRef<EditComponent>,
    private readonly userService: UserService
  ) {

    this.userPlan._id = appData.user._id;
    this.userPlan.plan = appData?.user?.plan || 'Basic';

  }


  edit(): void {

    this.loading = true;
    this.userService.updateUserPlan(this.userPlan).subscribe(
      () => {

        this.loading = false;
        this.dialogRef.close(this.userPlan.plan);

      }, (error) => {

        console.error(error);
        this.loading = false;

      }
    );

  }

}
